import Axios from "../config/Axios";
export const ApiCrearFormulario = async (data) => {
  try {
    const result = await Axios.post(`/POSTcreateFormularios`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiEditarFormulario = async (data) => {
  try {
    const result = await Axios.post(`/POSTeditFormularios`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

// export const ApiGetAllFormularios = async (data) => {
//   try {
//     const result = await AxiosContable.get(`/GETallFormularios`);
//     return result.data;
//   } catch (e) {
//     return [
//       {
//         sms: "err",
//         data: [],
//         mensaje: e,
//       },
//     ];
//   }
// };

export const ApiGetDataFormulario = async (pre_formulario_id) => {
  try {
    const result = await Axios.get(`/GETdataFormulario/${pre_formulario_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllFormByIdProyect = async (pre_proyecto_id) => {
  try {
    const result = await Axios.get(`/GETAllFormByIdProyect/${pre_proyecto_id}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllPreguntasByIdForm = async (pre_formulario_id) => {
  try {
    const result = await Axios.get(
      `/GETAllPreguntasByIdForm/${pre_formulario_id}`
    );
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllPreguntasByIdFormWithDates = async (data) => {
  try {
    const result = await Axios.get(`/GETAllPreguntasByIdFormWithDates`, {
      params: data,
    });
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetfirmularioXiD = async (data) => {
  try {
    const result = await Axios.get(`/GETFormularioID/${data}`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiDescargaFile = async (data) => {
  try {
    const result = await Axios.post(`/downloadStorageFile`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiDescargarArchivoEstatico = async (data) => {
  try {
    const result = await Axios.post(`/downloadStaticFile`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiActivarDesactivarFormulario = async (data) => {
  try {
    const result = await Axios.post(`/GETActivarDesactivarFormularios`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiPublicarDesPublicarFormulario = async (data) => {
  try {
    const result = await Axios.post(`/GETPublicarrDesactivarFormulario`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGenerateUrlFormulario = async (data) => {
  try {
    const result = await Axios.post(`/GETGenerateUrlFormulario`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiSaveAnonimoFormulario = async (data) => {
  try {
    const result = await Axios.post(`/POSTanonimo`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};


export const ApiCreateMantenimientoCase=async(data)=>{
  try{
   const result = await Axios.post("/POSTCreateMantenimientoCase",data)
   return result.data
  }catch(e){
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
}

export const ApiGetMantenimientoCase=async(data)=>{
  try{
   const result = await Axios.get("/POSTGetMantenimientoCase", {
    params: data,
  })
   return result.data
  }catch(e){
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
}

export const ApiGetTotalMantenimientoFichas=async(data)=>{
  try{
   const result = await Axios.get("/GETtotalMantenimientoFichas", {
    params: data,
  })
   return result.data
  }catch(e){
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
}



export const ApiGetMantenimientoCasexTecnico=async(data)=>{
  try{
   const result = await Axios.get("/POSTGetMantenimientoCaseXtecnico", {
    params: data,
  })
   return result.data
  }catch(e){
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
}

export const ApiGetTotalMantenimientoFichasXtecnico=async(data)=>{
  try{
   const result = await Axios.get("/GETtotalMantenimientoFichasXtecnico", {
    params: data,
  })
   return result.data
  }catch(e){
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
}