import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import "dayjs/locale/es"; // Importa el idioma español

export const parciarTimestampToDate = (fecha) => {
  dayjs.extend(relativeTime);
  dayjs.extend(localizedFormat);
  dayjs.locale("es");
  const now = dayjs();
  const inputDate = dayjs(fecha);
  const diffInDays = now.diff(inputDate, "day");

  if (diffInDays === 0) {
    return inputDate.fromNow();
  }
  if (diffInDays < 5) {
    return inputDate.fromNow();
  }
  return inputDate.format("DD/MM/YYYY HH:mm:ss");
};

export const parciarTimestampToTime = (fecha) => {
  if (!fecha) return "No hay fecha aún";
  dayjs.extend(localizedFormat);
  dayjs.extend(utc);
  dayjs.extend(timezone);

  dayjs.locale("es");
  const localFecha = dayjs.utc(fecha).add(5, 'hour').local();

  return localFecha.format("D [de] MMMM [de] YYYY ");
};
