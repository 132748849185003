import React, { useState, useEffect } from "react";
import TableValidExcelData from "./tabalcasosmantenimiento";
import TableErrExcelData from "./tableErrExcelData";
import InputFile from "./inputFile";
import * as xlsx from "xlsx";
import { ApiSyncUsersFromExcel } from "../../../../api/syncUsersFromExcel";
import toast from "react-hot-toast";
import { FaChartBar, FaCheckCircle } from "react-icons/fa";
import stringSimilarity from "string-similarity";

import ModalDefault from "../../../modalDefault";
import {
  Badge,
  Center,
  Group,
  RingProgress,
  Text,
  ThemeIcon,
} from "@mantine/core";
import { ApiGetAreasTree } from "../../../../api/areas";
import dayjs from "dayjs";
import {
  ApiGetMantenimientoCase,
  ApiGetTotalMantenimientoFichas,
} from "../../../../api/formularios";
import { toasterror, toastsuccess } from "../../../../helpers/toast1";
import {
    ApiactualizarCasofromecxcel,
  ApicrearDefecto,
  ApiEditarTiposDefectos,
  ApiEliminarTiposDefectos,
  ApiGetAllTecnicos,
  ApiObtenerTiposDefectos,
} from "../../../../api/tecnicos";
import MantenimientoTable from "../../mantenimientos/listarMantenimientos";

const CargaDeDatosCasosMantenimientos = ({ rol, session }) => {
  const [state, setState] = useState([]);
  const [loadsync, setLoadsync] = useState(false);
  const [modal, setModal] = useState(false);
  const [reportModal, setReportModal] = useState(false);
  const [report, setReport] = useState([]);
  const [mensaje, setmensaje] = useState(
    "Se ha iniciado sincronizacion de usuarios, espere en el pagina por favor..."
  );
  const [areasTree, setAreasTree] = useState([]);
  const [progress, setProgress] = useState({ completed: 0, total: 0 });

  let istecnico = false;

  //estados para controlar los colores de los filtros
  const [aplicafiltrogeneral, setAplicafiltrogeneral] = useState(false);
  const [aplicafiltroplanificacion, setAplicafiltroplanificacion] = useState(
    false
  );
  //cierre de estados para controlar los filtros
  const [filtroTecnicos, setFiltrosTecnicos] = useState([]);
  const estadosinit = [
    {
      state: "PENDIENTE",
      valor: 0,
    },
    {
      state: "PLANIFICADO",
      valor: 0,
    },

    {
      state: "FINALIZADO",
      valor: 0,
    },
  ];
  const [statedefecto, setStateDefecto] = useState({
    nombre: "",
    estado: true,
    tipo_defecto_id: "",
  });
  const [novedades, setNovedades] = useState({
    novedad: false,
    novedad_detalle: "",
    pre_ficha_id: "",
  });
  const [allDefectos, setAllDefectos] = useState([]);
  const [modaldefectos, setModalDefectos] = useState(false);
  const [listadoTecnicos, setListadoTecnicos] = useState([]);
  const [modalTecnico, setModalTecnico] = useState(false);
  const [modalListarTecnico, setModalListarTecnico] = useState(false);

  const [selecteddataTabla, setSelectedDataTabla] = useState("TOTAL");
  const [pageSize, setPageSize] = useState(350);
  const [mantenimientos, setMantenimientos] = useState([]);
  const [mantenimientos2, setMantenimientos2] = useState([]);
  const [totalencontrados, settotalencontrado] = useState([]);
  const [totalnocontrados, settotalnoencontrado] = useState([]);

  const [estados, setEstados] = useState(estadosinit);
  const [totalStados, setTotalEstados] = useState([]);
  const [dataTabla, setDataTabla] = useState([]);
  const [dateFilter, setDateFilter] = useState([
    dayjs().subtract(1, "month").toDate(),
    dayjs().toDate(),
  ]);

  const [modalexcel, setModalExcel] = useState(false);
  const [camposDisponibles, setCamposDisponibles] = useState([
    "ficha",
    "quienreporta",
    "fecha_reporte",
    "tecnicos",
    "fecha_planificada",
    "prioridad",
    "tipo_defecto",
    "numero_ot",
    "costo",
    "observacion",
    "estado",
    "fecha_cierre",
  ]);
  const [camposSeleccionados, setCamposSeleccionado] = useState(
    camposDisponibles
  );
  const [generateModelExcel, setGenerateModelExcel] = useState([]);

  const [caducados, setCaducados] = useState([]);
  const [xcaducar, setXcaducar] = useState([]);
  const [solicitudesTranferencias, setSolicitudesTranferencias] = useState([]);

  /*   useEffect(() => {
    const data = JSON.parse(localStorage.getItem("datauser"));
    setSesion(data);
  }, []); */

  /* useEffect(() => {
    const interval = setInterval(() => {
      if (session) {
        getAllMantenimientos();
      }
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(interval);
  }, [session]); */

  const filtrarPorCaducidad = (registros, diasAntesDeCaducar) => {
    const hoy = new Date();
    const rangoCaducidad = new Date();
    rangoCaducidad.setDate(hoy.getDate() + diasAntesDeCaducar);
    return registros.filter((registro) => {
      const fechaCaducidad = new Date(
        registro.mantenimientos_case.fecha_planificada
      );
      if (fechaCaducidad >= hoy && fechaCaducidad <= rangoCaducidad) {
        return registro;
      } else return null;
    });
  };

  const getAllMantenimientos = async () => {
    const inicio = dayjs(dateFilter[0]).format("DD-MM-YYYY");
    const fin = dayjs(dateFilter[1]).format("DD-MM-YYYY");
    const resttotal = await ApiGetTotalMantenimientoFichas({
      fecha_inicio: inicio,
      fecha_fin: fin,
    });
    if (resttotal.sms === "ok") {
      let auxdata = [];

      const totalPages = Math.ceil(resttotal.data / pageSize);

      for (let i = 1; i <= totalPages; i++) {
        const { data: fichas2, sms } = await ApiGetMantenimientoCase({
          fecha_inicio: inicio,
          fecha_fin: fin,
          page: i,
          pageSize: pageSize,
        });
        if (sms === "ok") {
          auxdata = auxdata.concat(fichas2);
        }
      }

      if (istecnico) {
        console.log("si es tecnico");
        auxdata = await filtrarSoloDatosdelTecnicoFn(auxdata);
      }
      const groupedData = groupByField(auxdata, "mantenimientos_case");
      setMantenimientos(auxdata);
      setMantenimientos2(auxdata);

      setEstados(groupedData);
      setTotalEstados(auxdata);
      if (selecteddataTabla === "TOTAL") {
        setDataTabla(auxdata);
      }
    } else {
      toasterror(resttotal.sms);
    }
  };

  const getAlertas = async () => {
    const restransferencias = await mantenimientos.filter(
      (datax) => datax.mantenimientos_case.estado_actividad === "TRANSFERENCIA"
    );

    setSolicitudesTranferencias(restransferencias);
  };

  const getAlertasxcaducar = async () => {
    const registrosPorCaducar = await filtrarPorCaducidad(mantenimientos, 5);
    setXcaducar(registrosPorCaducar);

    /* const resxcaducas=await data.filter((datax)=>dayjs(datax.mantenimientos_case.fecha_planificada).format("YYYY-MM-DD") ) */
  };
  const filtrarSoloDatosdelTecnicoFn = async (datamantenimientos) => {
    let auxdata = [];

    if (datamantenimientos.length === 0) return [];

    for (let index = 0; index < datamantenimientos.length; index++) {
      const verify =
        datamantenimientos[index].mantenimientos_case
          .mantenimiento_tecnicos_asignaciones;
      if (verify.length > 0) {
        for (let i = 0; i < verify.length; i++) {
          if (
            session.usuario === verify[i].mantenimiento_tecnicos.identificacion
          ) {
            if (auxdata.length > 0) {
              const resf = auxdata.filter(
                (datafilter) =>
                  datafilter.pre_ficha_id ===
                  datamantenimientos[index].pre_ficha_id
              );
              if (resf.length === 0) {
                auxdata.push(datamantenimientos[index]);
              }
            } else {
              auxdata.push(datamantenimientos[index]);
            }
          }
        }
      }
    }

    return auxdata;
  };

  const groupByField = (array, field) => {
    return array.reduce((grouped, item) => {
      const key = item[field].estado;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
      return grouped;
    }, {});
  };

  useEffect(() => {
    getAllMantenimientos();
  }, [istecnico]);

  useEffect(() => {
    if (mantenimientos.length > 0) {
      setTimeout(() => {
        getAlertasxcaducar();
        getAlertas();
      }, 1000);
    }
  }, [mantenimientos]);

  useEffect(() => {
    getAllTecnicos();
    allDefectosFn();
  }, []);

  const onchangedefecto = (e) => {
    if (e.target.name === "estado") {
      setStateDefecto({
        ...statedefecto,
        estado: !statedefecto.estado,
      });
    } else {
      setStateDefecto({
        ...statedefecto,
        [e.target.name]: e.target.value,
      });
    }
  };

  const allDefectosFn = async () => {
    const res = await ApiObtenerTiposDefectos();
    if (res.sms === "ok") {
      setAllDefectos(res.data);
    } else {
      toasterror(res.mensaje);
    }
  };

  const guardarDefectoFn = async () => {
    if (statedefecto.nombre === "") {
      toasterror("El campo nombre no debe estar vacío");
      return;
    }

    const res = await ApicrearDefecto(statedefecto);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setStateDefecto({
        ...statedefecto,
        nombre: "",
        estado: false,
        tipo_defecto_id: "",
      });
      allDefectosFn();
    } else {
      toasterror(res.mensaje);
    }
  };

  const editarDefectoFn = async () => {
    if (statedefecto.nombre === "") {
      toasterror("El campo nombre no debe estar vacío");
      return;
    }

    if (statedefecto.tipo_defecto_id === "") {
      toasterror(
        "no se ah identificado correctamente el tipo de defecto contacte a soporte"
      );
    }
    const res = await ApiEditarTiposDefectos(statedefecto);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setStateDefecto({
        ...statedefecto,
        nombre: "",
        estado: false,
        tipo_defecto_id: "",
      });
      allDefectosFn();
    } else {
      toasterror(res.mensaje);
    }
  };

  const eliminarDefectoFn = async (tipo_defecto_id) => {
    const res = await ApiEliminarTiposDefectos({ tipo_defecto_id });
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setStateDefecto({
        ...statedefecto,
        nombre: "",
        estado: false,
        tipo_defecto_id: "",
      });
      allDefectosFn();
    } else {
      toasterror(res.mensaje);
    }
  };

  const validaSession = () => {
    if (!session?.roles.code) {
      toasterror(
        "No hay sessión que podamos verificar,comuniquese con soporte"
      );
      return false;
    }
    if (session?.roles.code !== "5") {
      toasterror("No tienes permiso para realizar esta acción");
      return false;
    }

    return true;
  };

  const getFiles = (files) => {
    if (files.length > 0) {
      const reader = new FileReader();
      const selectedFile = files[0];
      reader.readAsArrayBuffer(selectedFile);
      reader.onload = async (e) => {
        const workbook = xlsx.read(e.target.result, { type: "buffer" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);

        const dax = await getarray(json);
        setState(dax);
      };

      reader.onerror = (e) => {
      };
    }
  };

  function excelSerialToDate(serial) {
    // Fecha base de Excel: 30 de diciembre de 1899
    const baseDate = dayjs("1899-12-30");
    return baseDate.add(serial, "day").format("YYYY-MM-DD");
  }

  const getarray = async (json) => {
    const x = await json.map((data) => {
      return {
        evento: data.evento ? data.evento.toString().trim() : "",
        prioridad: data.prioridad ? data.prioridad.toString().trim() : "",
        tecnico: data.tecnico ? data.tecnico.toString().trim() : "",
        ot: data.ot ? data.ot.toString().trim() : "",
        costo: data.costo ? data.costo.toString().trim() : "",
        planificacion: data.planificacion
          ? excelSerialToDate(data.planificacion.toString().trim())
          : "",
        observaciones: data.observaciones
          ? data.observaciones.toString().trim()
          : "",
        fila: data.__rowNum__ + 1,
      };
    });

    return x;
  };

  const comprobar = async () => {
    let encontrado = [];
    let noencontrado = [];
    let finalizados = [];
    let nofinalizados = [];

    const registrosEncontrados = await state.map((itemInicial) => {
      const registroCorrespondiente = mantenimientos2.filter((itemSecundario) =>
        itemSecundario.pre_fichas_preguntas[5].respuesta
          .trim()
          .includes(itemInicial.evento.trim())
      );

      const res = {
        ...itemInicial,
        registroCorrespondiente, // Se incluye el registro encontrado, si existe
      };

      if (res.registroCorrespondiente.length > 0) {
        encontrado.push(res);
        if (
          res.registroCorrespondiente[0].mantenimientos_case.estado ===
          "FINALIZADO"
        ) {
          finalizados.push(res);
        }else{
            nofinalizados.push(res)
        }
      } else {
        noencontrado.push(res);
      }
      return res;
    });

    let confecha=[]
    let sinfecha=[]

    console.log(encontrado, "encontrado");
    console.log(noencontrado, "no encontrado");
    console.log(finalizados, "finalizados");
    console.log(nofinalizados, "no finalizados");

    const res =await nofinalizados.map((data)=>{
        if(data.planificacion){
            confecha.push(data)
        }else{
            sinfecha.push(data)
        }
        return {
            mantenimiento_case_id:data.registroCorrespondiente[0].mantenimientos_case.mantenimiento_case_id,
            costo:data.costo,
            numero_ot:data.ot,
            observacion:data.observaciones,
            prioridad:data.prioridad==="A"?"ALTA":"MEDIA",
            fecha_planificada:data.planificacion,
            mantecnico_id:obtenertecnicos(data.tecnico.trim())
        }
    })

    console.log(confecha,"confecha")
    console.log(sinfecha,"sinfecha")
    console.log(res,"finaldatasendexcel")
    let tfinal=0
    let tfinalerros=0
    for (let index = 0; index < res.length; index++) {
        const final =await actualizarCasofromexcelfn(res[index])
        if(final){
            tfinal =tfinal+1
        }else{
            tfinalerros=tfinalerros+1
        }
    }

    console.log(tfinal,"finaltotal")
    console.log(tfinalerros,"finaltotalerrores")
    /*  const registrosEncontrados2 = await noencontrado.map((itemInicial) => {
        const registroCorrespondiente = mantenimientos2.filter(
          (itemSecundario) =>
            itemSecundario.pre_fichas_preguntas[5].respuesta.trim().includes(itemInicial.evento.trim())
        );
        console.log(registroCorrespondiente,"resgistroCorrespondiente")
        const res = {
          ...itemInicial,
          registroCorrespondiente, // Se incluye el registro encontrado, si existe
        };
  
        if (res.registroCorrespondiente) {
          encontrado.push(res);
        } else {
          noencontrado.push(res);
        }
        return res;
      });

      console.log(registrosEncontrados2,"registrosEncontrados2") */

    /*   const resstructura= await registrosEncontrados.map((data)=>{
        if(data.registroCorrespondiente.mantenimientos_case.estado!=="FINALIZADO"){
            let mantecnico_id=obtenertecnicos(data.tecnico.trim())
            console.log(mantecnico_id,`${data.tecnico}`)
            return {
                costo:data.costo,
                numero_ot:data.ot,
                observacion:data.observaciones, 
                prioridad:data.prioridad==="A"?"ALTA":"BAJA",
                fecha_planificada:data.planificacion,
                mantecnico_id:mantecnico_id
                
            }
        }
      })

      console.log(resstructura) */

    /*  for (let index = 0; index < state.length; index++) {
      const resx =await  mantenimientos2.map((data) => {
        if (
          data.pre_fichas_preguntas[5].respuesta.includes(state[index].evento)
        ) {
          return data;
        } else {
          noencontrado = [...noencontrado, state[index]];
        }
      });
      if (resx.length > 0) {
        encontrado = [...encontrado, resx];
      } else {
        noencontrado = [...noencontrado, state[index]];
      }
    } */
    /* console.log(state);
    console.log("=========================================");
    console.log(mantenimientos2);
    settotalencontrado(encontrado);
    settotalnoencontrado(noencontrado);
    setState(encontrado); */
  };

  const actualizarCasofromexcelfn = async (data) => {
     
      const res = await ApiactualizarCasofromecxcel(data);
      return res.sms === "ok" ? true : false;
      
    };

  const obtenertecnicos = (tecnicodata) => {
    const tecnico = listadoTecnicos.find(
      (data) =>
        `${data.infopersona.nombres.trim()} ${data.infopersona.apellidos.trim()}` ===
        tecnicodata.trim()
    );

    return tecnico?tecnico.mantecnico_id:null;
  };

  const getAllTecnicos = async () => {
    const res = await ApiGetAllTecnicos();
    if (res.sms === "ok") {
      setListadoTecnicos(res.data);
    } else {
      toasterror(res.mensaje);
    }
  };
  const sincronizar = async () => {
    if (state.length === 0) {
      toast.error("No hay datos para sincronizar");
      return;
    }

    setModal(true);
    setProgress({ completed: 0, total: state.length });
    setLoadsync(true);
    for (let i = 0; i < state.length; i++) {
      const data = state[i];
      const areaTree = await getAreaTree(data);
      let result;
      if (areaTree.sms === "err") {
        result = {
          sms: "err",
          status: "404",
          mensaje: areaTree.mensaje,
        };
      } else {
        const req = {
          ...data,
          ...areaTree.data,
        };

        result = await ApiSyncUsersFromExcel(req);
      }

      if (result.sms === "err") {
        setReport((old) => [
          ...old,
          {
            ...data,
            sms: "err",
            status: "500",
            mensaje: result.mensaje,
          },
        ]);
      } else {
        setReport((old) => [
          ...old,
          {
            ...data,
            sms: "ok",
            status: result.status,
            mensaje: result.mensaje,
          },
        ]);
      }
      setProgress((prevProgress) => ({
        ...prevProgress,
        completed: prevProgress.completed + 1,
      }));
    }
    setLoadsync(false);
    setmensaje(
      "Sincronización finalizada, cierra la ventana para ver el reporte"
    );
  };

  useEffect(() => {
    if (state.length > 0) {
    }
  }, [state]);

  const getAreaTree = async (data) => {
    try {
      const area = areasTree.find((area) => area.pre_area === data.area);
      if (!area) {
        return {
          sms: "err",
          mensaje: `Área '${data.area}' no encontrada`,
          data: null,
        };
      }

      const departamento = area.pre_departamentos.find(
        (departamento) => departamento.pre_departamento === data.departamento
      );
      if (!departamento) {
        return {
          sms: "err",
          mensaje: `Departamento '${data.departamento}' no encontrado`,
          data: null,
        };
      }

      const centro = departamento.pre_centros_costo.find(
        (centro) => centro.pre_centro_costo === data.centro
      );
      if (!centro) {
        return {
          sms: "err",
          mensaje: `Centro de costo '${data.centro}' no encontrado`,
          data: null,
        };
      }

      const cargo = centro.pre_cargos.find(
        (cargo) => cargo.pre_cargo === data.cargo
      );
      if (!cargo) {
        return {
          sms: "err",
          mensaje: `Cargo '${data.cargo}' no encontrado`,
          data: null,
        };
      }

      return {
        sms: "ok",
        mensaje: "Área tree obtenido correctamente",
        data: {
          area: area.pre_area_id,
          departamento: departamento.pre_departamento_id,
          centro: centro.pre_centro_costo_id,
          cargo: cargo.pre_cargo_id,
        },
      };
    } catch (error) {
      return {
        sms: "err",
        mensaje: error.message,
        data: null,
      };
    }
  };

  const closeActionModal = (data, bandera) => {
    setModal(data);
    setProgress({ completed: 0, total: 0 });
    setReportModal(true);
  };

  const closeActionModalReport = (data, bandera) => {
    setReportModal(data);
    setmensaje(
      "Se ha iniciado sincronizacion de usuarios, espere en el pagina por favor..."
    );
    setReport([]);
    setState([]);
  };

  return (
    <div className="p-2 d-flex flex-column">
      <div>
        <h5>Subir archivo de Excel</h5>
      </div>
      <hr />
      <div className="py-3">
        <InputFile
          getFiles={getFiles}
          accept={
            "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }
        />
      </div>
      <div>Total de registros ({mantenimientos2.length})</div>
      <div>Total de registros similares ({totalencontrados.length})</div>
      <div>Total de registros no similares({totalnocontrados.length})</div>

      <MantenimientoTable
        allDefectos={allDefectos}
        rol={"5"}
        isAdmin={true}
        istecnico={false}
        novedades={novedades}
        setNovedades={setNovedades}
        data={dataTabla}
        actualizarTabla={() => {}}
      />
      <br />
      {state.length > 0 ? (
        <>
          <div className="d-flex justify-content-between align-items-center">
            <h5>Datos válidos</h5>

            <button
              className="bgfondo2 bordecompleto bordesombreado p-3 negrita"
              onClick={sincronizar}
              style={{ background: "green", color: "white" }}
            >
              <i className="fas fa-sync"></i> Sincronizar con el servidor (
              {state.length} registros)
            </button>
            <button
              className="bgfondo2 bordecompleto bordesombreado p-3 negrita"
              onClick={comprobar}
              style={{ background: "green", color: "white" }}
            >
              <i className="fas fa-sync"></i> comprobar
            </button>
          </div>
          <hr />
          <TableValidExcelData data={state} />
        </>
      ) : (
        <div>No hay datos</div>
      )}

      <ModalDefault
        size="lg"
        modal={modal}
        bandera="excel"
        closeAction={closeActionModal}
        titulo="Reporte de sincronización"
        icontitulo={null}
      >
        <Text align="center">{mensaje}</Text>
        <Center>
          <div className="d-flex flex-column">
            {progress.completed !== state.length ? (
              <RingProgress
                sections={[
                  {
                    value: (progress.completed / progress.total) * 100,
                    color: "blue",
                  },
                ]}
                label={
                  <Text color="blue" weight={700} align="center" size="sm">
                    {progress.completed}/{progress.total}
                  </Text>
                }
              />
            ) : (
              <RingProgress
                sections={[{ value: 100, color: "teal" }]}
                label={
                  <Center>
                    <ThemeIcon
                      color="teal"
                      variant="light"
                      radius="xl"
                      size="xl"
                    >
                      <FaCheckCircle size={22} />
                    </ThemeIcon>
                  </Center>
                }
              />
            )}
          </div>
        </Center>
      </ModalDefault>
      <ModalDefault
        size="lg"
        modal={reportModal}
        bandera="excel"
        closeAction={closeActionModalReport}
        titulo="Reporte de sincronización"
        icontitulo={null}
      >
        <Text align="center">Reporte de sincronizacion</Text>
        <Center>
          <RingProgress
            size={150}
            thickness={10}
            roundCaps
            sections={[
              {
                value:
                  (report.filter(
                    (data) => data.sms === "ok" && data.status === "200"
                  ).length /
                    report.length) *
                  100,
                color: "teal",
              },
              {
                value:
                  (report.filter(
                    (data) => data.sms === "ok" && data.status === "201"
                  ).length /
                    report.length) *
                  100,
                color: "orange",
              },
              {
                value:
                  (report.filter((data) => data.sms === "err").length /
                    report.length) *
                  100,
                color: "red",
              },
            ]}
            label={
              <Center>
                <ThemeIcon color="blue" variant="light" radius="xl" size={100}>
                  <FaChartBar size={50} />
                </ThemeIcon>
              </Center>
            }
          />
        </Center>
        <Group position="center">
          <Badge color="teal" variant="filled" radius="xl">
            {
              report.filter(
                (data) => data.sms === "ok" && data.status === "200"
              ).length
            }{" "}
            Registros sincronizados
          </Badge>
          <Badge color="orange" variant="filled" radius="xl">
            {
              report.filter(
                (data) => data.sms === "ok" && data.status === "201"
              ).length
            }{" "}
            Registros creados
          </Badge>
          <Badge color="red" variant="filled" radius="xl">
            {report.filter((data) => data.sms === "err").length} Registros con
            errores
          </Badge>
        </Group>
        <div className="mt-3">
          <TableErrExcelData
            data={report.filter(
              (data) =>
                data.sms === "err" ||
                (data.sms === "ok" && data.status === "201")
            )}
          />
        </div>
      </ModalDefault>
    </div>
  );
};

export default CargaDeDatosCasosMantenimientos;
