import toast from "react-hot-toast";

export const toastsuccess =(mensaje)=>{
     toast.success(`${mensaje}`, {
        position: "bottom-right",
        style: {
          background: "green",
          color: "white",
        },
      });
}


export const toasterror =(mensaje)=>{
    toast.error(`${mensaje}`, {
       position: "bottom-right",
       style: {
         background: "red",
         color: "white",
       },
     });
}