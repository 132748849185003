import React, { useState, useEffect, useRef } from "react";
import { parciarTimestampToDate } from "./convertirFecha";
import { ICON } from "./BotonesCampoTabla";
import "./tablas.css";
const listFechas = ["create_at", "update_at"];
export default function TableWithContextMenu({
  columns,
  records,
  itemsPerPage,
  acciones,
  handleMenuAction,
  showPagination = true,
  handleFilterChange=()=>{},
}) {
  const [contextMenu, setContextMenu] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isMobile, setIsMobile] = useState(false);
  const contextMenuRef = useRef(null);

  const [accionesFiltradas, setAccionesFiltradas] = useState(acciones);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = records.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(records.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleContextMenu = (event, user) => {
    event.preventDefault();
    if (isMobile) {
      setContextMenu(true);
    } else {
      setContextMenu({ x: event.clientX, y: event.clientY });
    }
    setSelectedUser(user);
  };

  const handleContextMenuAction = (action) => {
    handleMenuAction(action, selectedUser);
    setContextMenu(null);
  }; /* */

  const handlePageChange = (page) => setCurrentPage(page);

  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    setCurrentPage(1)
  }, [records]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contextMenuRef.current &&
        !contextMenuRef.current.contains(event.target)
      ) {
        setContextMenu(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const filtarAcciones = () => {
    if (selectedUser?.estado_inicial === "BORRADOR") {
      return acciones.filter((accion) => accion !== "ver");
    }

    if (selectedUser?.estado_inicial !== "BORRADOR") {
      return ["ver"];
    }

    return acciones;
  };

  return (
    <div
      className="container-fluid bg-white rounded p-0 m-0"
      onClick={() => !isMobile && setContextMenu(null)}
    >
      {currentUsers.length > 0 ? (
        <div className="table-responsive">
          <div className="scrollable-table">
            <table className="table table-striped table-hover table-mc">
              <thead>
                <tr className="bgprincipal color-white">
                  {columns.map((data, i) => (
                    <th
                      key={i}
                      style={{
                        textAlign: data.center ? "center" : "center",
                      }}
                    >
                      <h6>{data.header}</h6>
                      
                      {data.filter?data.filter(data):null}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentUsers.map((user, id) => {
                  return (
                    <tr
                      className=""
                      key={id}
                      onContextMenu={(e) => handleContextMenu(e, user)}
                    >
                      {columns.map((column, key) => (
                        <td
                          key={key}
                          style={{
                            textAlign: column.center ? "center" : "left",
                            verticalAlign: "middle",
                            minWidth: column.minWidth,
                            maxWidth: column.maxWidth,

                          }}
                        >
                          {column.render ? (
                            <div className="w-100 d-flex justify-content-center align-items-center">
                              {column.render(user)}
                            </div>
                          ) : (
                            <h6>
                              {listFechas.includes(column.accessor)
                                ? parciarTimestampToDate(user[column.accessor])
                                : user[column.accessor]}
                            </h6>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div className="w-100 d-flex flex-column justify-content-center align-items-center align-self-center">
          <h6>
            <i className="bi bi-database-x" style={{ fontSize: "150px" }}></i>
          </h6>
          <h5>No hay datos</h5>
          <hr className="w-100" />
        </div>
      )}

      {showPagination ? (
        <nav
          aria-label="Page navigation"
          className="d-flex justify-content-between px-2 m-0"
        >
          <div className="d-flex flex-column">
            <h6>
              Página {currentPage} de {Math.ceil(records.length / itemsPerPage)}
            </h6>
            <h6> Total de registros ({records.length})</h6>
          </div>
          <ul className="pagination justify-content-center flex-wrap m-0 p-2">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link mx-1"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
            </li>

            {pageNumbers.length > 7 ? (
              <>
                {currentPage < 4 ? null : (
                  <li
                    className={`page-item ${currentPage === 1 ? "active" : ""}`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(1)}
                    >
                      1
                    </button>
                  </li>
                )}

                {/* Mostrar puntos suspensivos si no estamos cerca del principio */}
                {currentPage > 4 && (
                  <li
                    className="page-item px-2 bgwhite"
                    style={{ border: "none!important" }}
                  >
                    <span className="page-link">...</span>
                  </li>
                )}

                {/* Mostrar las páginas cercanas a la página actual */}
                {pageNumbers
                  .filter(
                    (number) =>
                      number >= currentPage - 2 && number <= currentPage + 2
                  )
                  .map((number, i) => (
                    <li
                      key={i}
                      className={`page-item ${
                        currentPage === number ? "active" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => handlePageChange(number)}
                      >
                        {number}
                      </button>
                    </li>
                  ))}

                {/* Mostrar puntos suspensivos si no estamos cerca del final */}
                {currentPage < pageNumbers.length - 3 && (
                  <li className="page-item disabled">
                    <span className="page-link">...</span>
                  </li>
                )}

                {/* Mostrar la última página siempre */}
                {pageNumbers.length - currentPage < 4 ? null : (
                  <li
                    className={`page-item ${
                      currentPage === pageNumbers.length ? "active" : ""
                    }`}
                  >
                    <button
                      className="page-link"
                      onClick={() => handlePageChange(pageNumbers.length)}
                    >
                      {pageNumbers.length}
                    </button>
                  </li>
                )}
              </>
            ) : (
              pageNumbers.map((number, i) => (
                <li
                  key={i}
                  className={` mx-1 page-item ${
                    currentPage === number ? "active" : ""
                  }`}
                >
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(number)}
                  >
                    {number}
                  </button>
                </li>
              ))
            )}

            <li
              className={`page-item ${
                currentPage === pageNumbers.length ? "disabled" : ""
              }`}
            >
              <button
                className="page-link mx-1"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === pageNumbers.length}
              >
                Next
              </button>
            </li>
          </ul>

          {/*  <ul className="pagination justify-content-center flex-wrap m-0 p-2">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link mx-1"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            >
              Previous
            </button>
          </li>
          {pageNumbers.map((number, i) => (
            <li
              key={i}
              className={` mx-1 page-item ${currentPage === number ? "active" : ""
                }`}
            >
              <button
                className="page-link"
                onClick={() => handlePageChange(number)}
              >
                {number}
              </button>
            </li>
          ))}
          <li
            className={`page-item ${currentPage === pageNumbers.length ? "disabled" : ""
              }`}
          >
            <button
              className="page-link mx-1"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === pageNumbers.length}
            >
              Next
            </button>
          </li>
        </ul> */}
        </nav>
      ) : null}

      {contextMenu && (
        <div
          ref={contextMenuRef}
          className={`context-menu p-2 ${isMobile ? "mobile-menu" : ""}`}
          style={
            !isMobile
              ? {
                  position: "fixed",
                  top: contextMenu.y,
                  left: contextMenu.x,
                  zIndex: 1000,
                }
              : {}
          }
        >
          {isMobile && (
            <div className="mobile-menu-header">
              Opciones para {selectedUser?.name}
            </div>
          )}
          {filtarAcciones().map((accion, i) => (
            <button
              key={i}
              className="dropdown-item my-1"
              onClick={() => handleContextMenuAction(accion)}
            >
              <i className={`bi ${ICON[accion]} `}></i> {accion}
            </button>
          ))}
        </div>
      )}

      <style>{`
        .scrollable-table {
          overflow-x: auto;
          width: 100%;
        }
        .table {
          min-width: 600px;
        }
        .context-menu {
          min-width: 120px;
          background-color: #fff;
          border: 1px solid rgba(0, 0, 0, 0.15);
          border-radius: 0.25rem;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
        }
        .mobile-menu {
          position: fixed;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1050;
          background-color: #fff;
          border-top: 1px solid #dee2e6;
          border-radius: 1rem 1rem 0 0;
          padding: 1rem;
        }
        .mobile-menu-header {
          font-weight: bold;
          margin-bottom: 0.5rem;
          padding-bottom: 0.5rem;
          border-bottom: 1px solid #dee2e6;
        }
        .dropdown-item {
          cursor: pointer;
        }
      `}</style>
    </div>
  );
}
