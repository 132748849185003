import React, { useEffect, useState } from "react";
import ModalDefault from "../../modalDefault";
import TextareaAutosize from "react-textarea-autosize";

import dayjs from "dayjs";
import { ApiDescargaFile } from "../../../api/formularios";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import MyCustomMP4Renderer from "../../customVideo";
import {
  ApiFechaPlanificada,
  ApiCerrarCaso,
  ApiGetAllTecnicos,
  ApiGetAllTecnicosAsignados,
  ApiQuitarTecnicosAsignados,
  ApiactualizarCaso,
  ApicreartecnicoAsignacion,
  ApisolicitudTransferenciaFichaDelCaso,
  ApiCancelarsolicitudTransferenciaFichaDelCaso,
} from "../../../api/tecnicos";
import { toasterror, toastsuccess } from "../../../helpers/toast1";
import TableWithContextMenu from "../../../helpers/tablas";

const MantenimientoTable = ({
  data,
  actualizarTabla,
  allDefectos,
  rol,
  istecnico,
  novedades,
  setNovedades,
}) => {
  const [listadoTecnicos, setListadoTecnicos] = useState([]);
  const [listadoTecnicosAsiganados, setListadoTecnicosAsiganados] = useState(
    []
  );
  const [fullScreenfichaModal, setFullscreenFichaModal] = useState(false);
  const [modalCierreCaso, setModalCierreCaso] = useState(false);
  const [modalFechaPlanificada, setModalFechaPlanificada] = useState(false);
  const [modalcase, setModalCase] = useState(false);
  const [modalReporte, setModalReporte] = useState(false);
  const [modalTecnicoAsignar, setModalTecnicoAsignar] = useState(false);
  const [infoSeletedReporte, setInfoSelectedReporte] = useState(null);
  const [urlDocument, setUrlDocument] = useState([]);
  const [staticFilesTemp, setStaticFilesTemp] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCase, setSelectedCase] = useState(null);
  const [stateCase, setStateCase] = useState({
    mantenimiento_case_id: "",
    prioridad: "",
    estado: "",
    numero_ot: "",
    costo: "",
    fecha_planificada: "",
    anio_ejecutado: "",
    observacion: "",
    activo: "",
    tipo_defecto: [],
  });

  const [fechaplanificada, setFechaPlanificada] = useState({
    mantenimiento_case_id: "",
    fecha_planificada: "",
  });
  const [cerrarCaso, setCerrarCaso] = useState({
    mantenimiento_case_id: "",
  });

  useEffect(() => {}, []);

  const mapearpreguntaopciones = (res) => {
    let r =
      res.respuesta === ""
        ? [""]
        : JSON.parse(res.respuesta).map((record) =>
            record.selected === true ? record.opcion : ""
          );

    if (
      !(
        res.observacion === null ||
        res.observacion === undefined ||
        res.observacion === ""
      )
    ) {
      r.push(` || Observación: ${res.observacion}`);
    }

    return r;
  };

  const onDowload = async () => {
    for (const fileUrl of urlDocument) {
      const { sms, url } = await ApiDescargaFile({ file: fileUrl.file });
      if (sms === "ok") {
        window.open(url, "_blank");
      }
    }
  };
  const buildLinks = async (links) => {
    let linksTemp = [];
    for (const fileUrl of links) {
      const { sms, url, fileType, fileName } = await ApiDescargaFile({
        file: fileUrl.file,
      });
      if (sms === "ok") {
        linksTemp.push({
          uri: url,
          fileName,
          fileType,
        });
      }
    }
    setStaticFilesTemp(linksTemp);
  };

  const mapearpreguntasFiles = (res) => {
    function toLinks(links) {
      let r = "";
      links.forEach((link) => {
        r += `${link.file} , `;
      });
      return r;
    }

    return res.respuesta === "" ? (
      "Sin archivos"
    ) : (
      <>
        <button
          name={toLinks(JSON.parse(res.respuesta))}
          onClick={(e) => {
            setShowModal(true);
            setUrlDocument(JSON.parse(res.respuesta));
            buildLinks(JSON.parse(res.respuesta));
          }}
          className="bg-btn-vet"
        >
          {`Ver (${JSON.parse(res.respuesta).length} archivo${
            JSON.parse(res.respuesta).length > 1 ? "s" : ""
          })`}
        </button>
        {!(
          res.observacion === "" ||
          res.observacion === null ||
          res.observacion === undefined
        ) && <p className="text-table-sm">Observación: {res.observacion}</p>}
      </>
    );
  };

  const onClosedModal = () => {
    setShowModal(false);
    setUrlDocument([]);
    setStaticFilesTemp([]);
  };

  const getAllTecnicos = async () => {
    const res = await ApiGetAllTecnicos();
    console.log(res);
    if (res.sms === "ok") {
      setListadoTecnicos(res.data);
    } else {
      toasterror(res.mensaje);
    }
  };

  const getAllTecnicosAsignados = async (infoSeletedReporte) => {
    const data = infoSeletedReporte.mantenimientos_case.mantenimiento_case_id;
    const res = await ApiGetAllTecnicosAsignados(data);
    console.log(res, "tecnicos asignados");
    if (res.sms === "ok") {
      setListadoTecnicosAsiganados(res.data);
    } else {
      toasterror(res.mensaje);
    }
  };

  const asignarTecnico = async (infoSeletedReporte, datatecnico) => {
    const st = {
      mantecnico_id: datatecnico.mantecnico_id,
      mantenimiento_case_id:
        infoSeletedReporte.mantenimientos_case.mantenimiento_case_id,
    };
    const res = await ApicreartecnicoAsignacion(st);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      getAllTecnicosAsignados(infoSeletedReporte);
    } else {
      toasterror(res.mensaje);
    }
  };

  const quitarTecnicoAsignado = async (mantecnico_id) => {
    const res = await ApiQuitarTecnicosAsignados(mantecnico_id);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      getAllTecnicosAsignados(infoSeletedReporte);
    } else {
      toasterror(res.mensaje);
    }
  };

  const onchangecase = (e) => {
    setStateCase({
      ...stateCase,
      [e.target.name]: e.target.value,
    });
  };

  const actualizarCaso = async () => {
    if (stateCase.estado === "FINALIZADO") {
      toasterror(
        "EL REGISTRO NO PUEDE SER MODIFICADO PORQUE EL CASO YA FUE FINALIZADO"
      );
    }
    if (stateCase.mantenimiento_case_id == "") {
      toasterror("Error, No se ha seleccionado ningún identificador del caso");
      return;
    }
    const res = await ApiactualizarCaso(stateCase);
    if (res.sms === "ok") {
      setSelectedCase(null);
      toastsuccess(res.mensaje);
      setModalCase(false);
      actualizarTabla();
    } else {
      toasterror(res.mensaje);
    }
  };

  const guardarFechaPlanificadaFn = async () => {
    const res = await ApiFechaPlanificada(fechaplanificada);
    console.log(res);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setFechaPlanificada({
        mantenimiento_case_id: "",
        fecha_planificada: "",
      });
      setModalFechaPlanificada(false);
    } else {
      toasterror(res.mensaje);
    }
  };
  const cerrarCasoFn = async () => {
    const res = await ApiCerrarCaso(cerrarCaso);
    console.log(res);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setCerrarCaso({
        ...cerrarCaso,
        mantenimiento_case_id: "",
      });
      setModalFechaPlanificada(false);
    } else {
      toasterror(res.mensaje);
    }
  };

  const onchangedefectos = async (datax) => {
    let aux = [...allDefectos];
    const res = await aux.map((data) => {
      if (data.tipo_defecto_id === datax.tipo_defecto_id) {
        data.estado = !data.estado;
      }
      return data;
    });
    setStateCase({
      ...stateCase,
      tipo_defecto: res,
    });
  };

  useEffect(() => {
    if (
      infoSeletedReporte &&
      infoSeletedReporte.mantenimientos_case.estado_actividad ===
        "TRANSFERENCIA"
    ) {
      console.log(novedades, "novedades");
      setNovedades({
        ...novedades,
        novedad: true,
        novedad_detalle: infoSeletedReporte.mantenimientos_case.novedad_detalle,
        pre_ficha_id: infoSeletedReporte.pre_ficha_id,
        isfromdb: true,
      });
    } else {
      setNovedades({
        ...novedades,
        novedad: false,
        novedad_detalle: "",
        pre_ficha_id: "",
        isfromdb: false,
      });
    }
  }, [infoSeletedReporte]);

  const solicitudDeTransferenciaFichaFn = async () => {
    const res = await ApisolicitudTransferenciaFichaDelCaso(novedades);
    console.log(res);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setModalReporte(false);
      setInfoSelectedReporte(null);
      actualizarTabla();
    } else {
      toasterror(res.mensaje);
    }
  };

  const cancelarsolicitudDeTransferenciaFichaFn = async () => {
    const res = await ApiCancelarsolicitudTransferenciaFichaDelCaso(novedades);
    console.log(res);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setModalReporte(false);
      setInfoSelectedReporte(null);
      actualizarTabla();
    } else {
      toasterror(res.mensaje);
    }
  };

  const [activefilter, setActiveFilter] = useState({});
  const columns = [
    {
      header: "OPCIONES",
      minWidth: "10px",
      accessor: "empresa",
      render: (data) => (
        <div className="text-center td-container ">
          {data.mantenimientos_case.fecha_cierre ? (
            <div className="td-container">
              <div
                className="content-box bg-success"
                style={{
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                {data.mantenimientos_case.prioridad ? (
                  <label> CERRADO</label>
                ) : (
                  "NO DEFINIDO"
                )}
              </div>
            </div>
          ) : data.mantenimientos_case.estado_actividad === "TRANSFERENCIA" ? (
            <span
              style={{ fontSize: "14px" }}
              className="badge bg-dark W-100 content-box"
            >
              TRANSFIRIENDO ...
            </span>
          ) : (
            <div>
              <button
                onClick={() => {
                  if (!data.mantenimientos_case.fecha_planificada) {
                    toasterror(
                      "NO SE PUEDE FINALIZAR EL REGISTRO SIN UNA FECHA DE PLANIFICACIÓN"
                    );
                    return;
                  }
                  if (!data.mantenimientos_case.prioridad) {
                    toasterror(
                      "NO SE PUEDE FINALIZAR EL REGISTRO SIN UNA PRIORIDAD ESTABLECIDA"
                    );
                    return;
                  }
                  if (!data.mantenimientos_case.costo) {
                    toasterror(
                      "NO SE PUEDE FINALIZAR EL REGISTRO SIN DEFINIR EL COSTO DEL MANTENIMIENTO"
                    );
                    return;
                  }
                  setCerrarCaso({
                    ...cerrarCaso,
                    mantenimiento_case_id:
                      data.mantenimientos_case.mantenimiento_case_id,
                  });
                  setSelectedCase(data.mantenimientos_case);
                  setModalCierreCaso(true);
                }}
                className="shadow border btn btn-primary "
                style={{
                  color: "white",
                  background: "green",
                  fontWeight: "bold",
                  borderRadius: "10px",
                }}
              >
                <i className="fas fa-check"></i>
              </button>
              <button
                onClick={() => {
                  setStateCase({
                    ...stateCase,
                    mantenimiento_case_id:
                      data.mantenimientos_case.mantenimiento_case_id,
                    prioridad: data.mantenimientos_case.prioridad
                      ? data.mantenimientos_case.prioridad
                      : "",
                    estado: data.mantenimientos_case.estado
                      ? data.mantenimientos_case.estado
                      : "",
                    numero_ot: data.mantenimientos_case.numero_ot
                      ? data.mantenimientos_case.numero_ot
                      : "",
                    costo: data.mantenimientos_case.costo
                      ? data.mantenimientos_case.costo
                      : "",

                    observacion: data.mantenimientos_case.observacion
                      ? data.mantenimientos_case.observacion
                      : "",
                    tipo_defecto: data.mantenimientos_case.tipo_defecto,
                  });
                  setModalCase(true);
                }}
                className="shadow border btn btn-primary "
                style={{
                  color: "white",
                  background: "blue",
                  fontWeight: "bold",
                  borderRadius: "10px",
                }}
              >
                <i className="fas fa-edit"></i>
              </button>
            </div>
          )}
        </div>
      ),
    },
    /* {
      header: "FICHA",
      accessor: "ficha",
      render: (data) => (
        <div className="text-center ">
          
        </div>
      ),
    }, */
    {
      minWidth: "160px",
      center: "center",
      header: "REPORTADO POR",
      accessor: "nombres",
      //filter: ({accessor}) => <input type="text" />,
      render: (data) => (
        <div
          style={{ cursor: "pointer" }}
          className="w-100 d-flex justify-content-center align-items-center flex-column py-3"
          onClick={() => {
            setModalReporte(true);
            setInfoSelectedReporte(data);
            getAllTecnicosAsignados(data);
          }}
        >
          <label htmlFor="" style={{ fontWeight: "bold", cursor: "pointer" }}>
            {data.nombres.toUpperCase()}
          </label>
          <label htmlFor="" style={{ fontWeight: "bold", cursor: "pointer" }}>
            ( {dayjs(data.created_at).format("DD/MM/YYYY HH:MM")} )
          </label>
          <button
            className="shadow mx-2 d-flex justify-content-between align-items-center"
            style={{
              fontSize: "14px",
              borderRadius: "15px",
              background: "#3f3f3f",
              border: "none",
              color: "white",
            }}
          >
            <strong className="mx-2">{data.pre_ficha_id}</strong>
            <div
              style={{ borderRadius: "30px" }}
              className="px-2  bg-info text-white "
            >
              <i className="fas fa-info"></i>
            </div>
          </button>
        </div>
      ),
    },

    {
      header: "TECNICOS",
      accessor: "tecnicos",
      render: (data) => (
        <td className="text-start">
          <div className="w-100 d-flex justify-content-start align-items-start flex-column">
            {data.mantenimientos_case.mantenimiento_tecnicos_asignaciones
              .length > 0 ? (
              data.mantenimientos_case.mantenimiento_tecnicos_asignaciones.map(
                (data) => (
                  <label
                    htmlFor=""
                    // style={{ borderBottom: "1px solid black" }}
                  >
                    {<strong> * </strong>}
                    {data.mantenimiento_tecnicos.infopersona.nombres}{" "}
                    {data.mantenimiento_tecnicos.infopersona.apellidos}.
                  </label>
                )
              )
            ) : (
              <div className="text-center">- S/N -</div>
            )}
          </div>
        </td>
      ),
      minWidth: "200px",
      textAlign: "start",
    },

    {
      header: "PLANIFICADO",
      accessor: "planificado",
      render: (data) => (
        <div className="text-center  mx-2 ">
          <span className="spantablewhite badge text-bg-light shadow p2 border d-flex justify-content-between align-items-center">
            {data.mantenimientos_case.fecha_planificada ? (
              <label>
                {dayjs(data.mantenimientos_case.fecha_planificada).format(
                  "DD-MM-YYYY"
                )}
              </label>
            ) : (
              <label className="">NO PLANIFICADO</label>
            )}
            {istecnico ||
            data.mantenimientos_case.estado_actividad ===
              "TRANSFERENCIA" ? null : (
              <button
                onClick={() => {
                  if (data.mantenimientos_case.fecha_cierre) {
                    toasterror("Este registro se encuentra finalizado");
                    return;
                  }
                  setFechaPlanificada({
                    ...fechaplanificada,
                    mantenimiento_case_id:
                      data.mantenimientos_case.mantenimiento_case_id,
                  });
                  setSelectedCase(data.mantenimientos_case);
                  setModalFechaPlanificada(true);
                }}
                className="shadow border mx-1 p-2 "
                style={{
                  color: "white",
                  background: "black",
                  borderRadius: "20px",
                }}
              >
                <i className="fas fa-edit "></i>
              </button>
            )}
          </span>
        </div>
      ),
    },
    {
      header: "PRIORIDAD",
      accessor: "prioridad",
      render: (data) => (
        <div className="td-container">
          <div
            className="content-box"
            style={{
              fontWeight: "bold",
              background:
                data.mantenimientos_case.prioridad === "ALTA"
                  ? "red"
                  : data.mantenimientos_case.prioridad === "MEDIA"
                  ? "yellow"
                  : data.mantenimientos_case.prioridad === "BAJA"
                  ? "blue":"grey",
              color:
                data.mantenimientos_case.prioridad === "ALTA"
                  ? "white"
                  : data.mantenimientos_case.prioridad === "MEDIA"
                  ? "black"
                  : "white",
            }}
          >
            {data.mantenimientos_case.prioridad ? (
              <label> {data.mantenimientos_case.prioridad}</label>
            ) : (
              "NO DEFINIDO"
            )}
          </div>
        </div>
      ),
    },
    {
      header: "DEFECTOS",
      maxWidth:"180px",
      accessor: "defecto",
      render: (data) => (
        <div className="text-center">
          {data.mantenimientos_case.tipo_defecto &&
          data.mantenimientos_case.tipo_defecto.length > 0 ? (
            <div className="w-100">
              {data.mantenimientos_case.tipo_defecto.map((data) => {
                if (data.estado) {
                  return <label className="w-100"> * {data.nombre} </label>;
                }
              })}
            </div>
          ) : (
            "- S/N -"
          )}
        </div>
      ),
    },
    {
      header: "NUMERO O/T",
      accessor: "empresa",
      render: (data) => (
        <div className="text-center">
          {data.mantenimientos_case.numero_ot
            ? data.mantenimientos_case.numero_ot
            : "- S/N -"}
        </div>
      ),
    },
    {
      header: "COSTO",
      accessor: "costo",
      render: (data) => (
        <div className="text-center">
          {data.mantenimientos_case.costo
            ? data.mantenimientos_case.costo
            : "- S/N -"}
        </div>
      ),
    },
    {
      header: "OBSERVACION",
      accessor: "observacion",
      render: (data) => (
        <div className="text-center">
          {data.mantenimientos_case.observacion
            ? data.mantenimientos_case.observacion
            : "- S/N -"}
        </div>
      ),
    },
    {
      header: "ESTADO",
      accessor: "estado",
      render: (data) => (
        <div className="text-center">
          {data.mantenimientos_case.estado ? (
            <span
              className={
                data.mantenimientos_case.estado === "FINALIZADO"
                  ? "badge bg-success  span1 shadow"
                  : data.mantenimientos_case.estado === "PLANIFICADO"
                  ? "badge bg-warning text-dark span1 shadow"
                  : "badge bg-danger span1 shadow"
              }
            >
              <h6> {data.mantenimientos_case.estado}</h6>
            </span>
          ) : (
            <span
              className="badge bg-light span1 shadow text-dark "
              style={{ fontSize: "13px" }}
            >
              NO DEFINIDO
            </span>
          )}
        </div>
      ),
    },
    {
      header: "FECHA_CIERRE",
      accessor: "fecha_cierre",
      render: (data) => (
        <div className="text-center">
          {data.mantenimientos_case.fecha_cierre
            ? dayjs(data.mantenimientos_case.fecha_cierre).format("YYYY-MM-DD")
            : "- SIN CERRAR -"}
        </div>
      ),
    },
  ];

  {
    /*  <td className="text-center">{data.mantenimientos_case.activo ? "SI" : "NO"}</td> */
  }

  const handleMenuAction = () => {};

  return (
    <div
      className="container px-3"
      style={{ position: "relative", zIndex: "1" }}
    >
      {/* <FixedHeaderTable columns={columns} data={data} height="400px"/> */}
      <TableWithContextMenu
        columns={columns}
        records={data}
        itemsPerPage={5}
        acciones={[]}
        handleMenuAction={() => {}}
        showPagination={true}
      />

      {/** MODAL REPORTE DE FICHA DEL CASO DE MANTENIMIENTO */}
      <ModalDefault
        bandera={"reportemantenimiento"}
        modal={modalReporte}
        size={"lg"}
        fullscreen={fullScreenfichaModal}
        needfullscreen={true}
        onClickFullscreen={() => {
          setFullscreenFichaModal(!fullScreenfichaModal);
        }}
        icontitulo={<null />}
        titulo={`Detalle del reporte`}
        closeAction={() => {
          setInfoSelectedReporte(null);
          setModalReporte(false);
          actualizarTabla();
        }}
      >
        <div className="row pb-4 ">
          <div className="col-md-12">
            {infoSeletedReporte ? (
              <div className="row">
                <div
                  className={
                    istecnico &&
                    infoSeletedReporte.mantenimientos_case.estado_actividad ===
                      "TRANSFERENCIA"
                      ? "col-md-12"
                      : "col-md-6"
                  }
                >
                  <div className="w-100">
                    <div>
                      <div>
                        <strong>QUIEN REPORTA: </strong>
                        {infoSeletedReporte.nombres}{" "}
                        <strong>CON CÉDULA: </strong>
                        {infoSeletedReporte.cedula}
                      </div>

                      <div>
                        <strong>FECHA DEL REPORTE :</strong>
                        <label>
                          {dayjs(infoSeletedReporte.created_at).format(
                            "YYYY-MM-DD"
                          )}
                        </label>
                      </div>
                    </div>
                    <hr />
                    {infoSeletedReporte.pre_fichas_preguntas.length > 0 ? (
                      infoSeletedReporte.pre_fichas_preguntas.map((data) => {
                        const valida = ["text", "date", "time"];

                        let resp = valida.includes(
                          data.pre_preguntas.pre_tipos_opciones.tipo
                        )
                          ? `${data.respuesta} ${
                              data.observacion === "" ||
                              data.observacion === null ||
                              data.observacion === undefined
                                ? ""
                                : " || Observación: " + data.observacion
                            }`
                          : data.pre_preguntas.pre_tipos_opciones.tipo ===
                            "file"
                          ? mapearpreguntasFiles(data)
                          : mapearpreguntaopciones(data);
                        return (
                          <div className="w-100">
                            <strong className="w-100">
                              {data.pre_preguntas.pregunta}
                            </strong>
                            <p>{resp}</p>
                          </div>
                        );
                      })
                    ) : (
                      <div>No exiten preguntas</div>
                    )}
                  </div>
                </div>
                {!istecnico &&
                infoSeletedReporte.mantenimientos_case.estado_actividad !==
                  "TRANSFERENCIA" ? (
                  <div className="col-md-6">
                    <div className="border shadow rounded w-100 h-100 d-flex justifu-content-start align-items-start">
                      <div className="w-100 text-center p-2 d-flex flex-column justify-content-start align-items-center">
                        <div className="w-100">
                          <div className="d-flex w-100 flex-column justify-content-center align-items-center">
                            <div className="d-flex justify-content-between align-items-center w-100">
                              <h4 className="">Técnicos asignados</h4>
                              <div
                                className=""
                                style={{
                                  width: "65px!important",
                                  maxWidth: "65px",
                                }}
                              >
                                <button
                                  onClick={() => {
                                    getAllTecnicosAsignados(infoSeletedReporte);
                                  }}
                                  className="shadow border rounded "
                                  style={{
                                    background: "green",
                                    color: "white",
                                    borderRadius: "15px",
                                  }}
                                >
                                  <i className="fas fa-rotate"></i>
                                </button>
                                <button
                                  onClick={() => {
                                    getAllTecnicos();
                                  }}
                                  className="shadow border rounded "
                                  style={{
                                    background: "blue",
                                    color: "white",
                                    borderRadius: "15px",
                                  }}
                                >
                                  <i className="fas fa-list"></i>
                                </button>
                              </div>
                            </div>
                            <hr />
                            {listadoTecnicosAsiganados.length > 0 ? (
                              <table className="w-100">
                                <tbody
                                  className="w-100"
                                  style={{
                                    maxHeight: "300px",
                                    minWidth: "0px",
                                    overflowY: "scroll",
                                  }}
                                >
                                  {listadoTecnicosAsiganados.map(
                                    (data, index) => (
                                      <tr className="w-100 border" key={index}>
                                        <td className="w-100 text-center d-flex justify-content-between align-items-center">
                                          <div className="d-flex flex-row justy-content-center align-items-center">
                                            <div
                                              className="mx-2"
                                              style={{
                                                width: "30px ",
                                                borderRight:
                                                  "1px solid black h-100",
                                                background: "blue",
                                                color: "white",
                                                fontSize: "18px",
                                                borderRadius: "30px",
                                              }}
                                            >
                                              {index + 1}
                                            </div>
                                            <div className="d-flex flex-column d-flex justify-content-start align-items-start">
                                              <h6>
                                                {
                                                  data.mantenimiento_tecnicos
                                                    .infopersona.nombres
                                                }{" "}
                                                {
                                                  data.mantenimiento_tecnicos
                                                    .infopersona.apellidos
                                                }
                                              </h6>
                                              <h6>
                                                <strong>
                                                  Identificación:{" "}
                                                </strong>
                                                {
                                                  data.mantenimiento_tecnicos
                                                    .infopersona.identificacion
                                                }
                                              </h6>
                                            </div>
                                          </div>
                                          <div>
                                            <button
                                              style={{
                                                background: "red",
                                                color: "white",
                                              }}
                                              className="shadow border rounded m-1"
                                              onClick={() =>
                                                quitarTecnicoAsignado(
                                                  data.mantecasig_id
                                                )
                                              }
                                            >
                                              Quitar
                                            </button>
                                          </div>
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            ) : (
                              <div className="w-100 d-flex flex-column h-100 justify-content-center align-items-center">
                                <i
                                  className="fa-regular fa-id-badge"
                                  style={{ fontSize: "25px" }}
                                ></i>{" "}
                                <h5>No se asignaron técnicos a esta ficha</h5>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className=" w-100 mt-3 p-3">
                          <div
                            className="  shadow"
                            style={{
                              backgroundColor: "black",
                              borderRadius: "12px",
                            }}
                          >
                            {listadoTecnicos.length > 0 ? (
                              <div className="w-100 d-flex flex-column p-3">
                                <div className="d-flex justify-content-between align-items-center">
                                  <h6 className="text-white">
                                    Técnicos disponibles
                                  </h6>
                                  <div
                                    className="d-flex justify-content-center aling-items-center px-1"
                                    style={{ width: "65px!important" }}
                                  >
                                    <button
                                      onClick={() => {
                                        setListadoTecnicos([]);
                                      }}
                                      className="text-white shadow mx-1"
                                      style={{
                                        border: "none",
                                        background: "green",
                                      }}
                                    >
                                      <i className="fas fa-rotate"></i>
                                    </button>
                                    <button
                                      onClick={() => {
                                        setListadoTecnicos([]);
                                      }}
                                      className="text-white shadow"
                                      style={{
                                        border: "none",
                                        background: "red",
                                      }}
                                    >
                                      <i className="fas fa-close"></i>
                                    </button>
                                  </div>
                                </div>
                                <hr />
                                <table className="w-100">
                                  <tbody
                                    style={{
                                      maxHeight: "10px",
                                      minWidth: "0px",
                                      overflowY: "scroll",
                                    }}
                                  >
                                    {listadoTecnicos.map((data, index) => (
                                      <tr
                                        key={index}
                                        className="w-100  d-flex justify-content-start align-items-center py-2"
                                        style={{ borderTop: "1px solid white" }}
                                      >
                                        <td className="text-white text-start d-flex w-100 justify-content-between align-items-center">
                                          <h6>
                                            {" "}
                                            ({index + 1}){" "}
                                            {data.infopersona.nombres}{" "}
                                            {data.infopersona.apellidos}
                                          </h6>
                                          <button
                                            onClick={() =>
                                              asignarTecnico(
                                                infoSeletedReporte,
                                                data
                                              )
                                            }
                                            style={{
                                              background: "blue",
                                              color: "white",
                                              border: "1px solid blue",
                                              borderRadius: "15px",
                                            }}
                                            className="shadow  rounded m-1"
                                          >
                                            Asignar
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {istecnico ? null : (
                  <div className="col-md-12">
                    <hr />
                    <div className="row">
                      <div className="d-flex justify-content-start align-items-cenetr flex-column">
                        <div className="d-flex flex-row">
                          <input
                            type="checkbox"
                            className="mr-2"
                            checked={novedades.novedad}
                            onChange={() => {
                              setNovedades({
                                ...novedades,
                                novedad: !novedades.novedad,
                                pre_ficha_id: infoSeletedReporte.pre_ficha_id,
                                isfromdb: novedades.isfromdb,
                              });
                            }}
                          />
                          <label htmlFor="" className="px-2">
                            <strong>Generar solicitud de transferencia.</strong>
                          </label>
                        </div>

                        <label htmlFor="" className="text-muted ">
                          Al marcar esta casilla estás indicando que el reporte
                          no está relacionado con las condiciones inseguras y se
                          enviará al departamento de seguridad para su
                          respectiva revisión donde será transferido a su
                          formulario correspondiente.
                        </label>
                        {novedades.novedad ? (
                          <div className="w-100 d-flex flex-column align-items-center justify-content-center py-2">
                            <string htmlFor="" className="text-start w-100">
                              Comentario sobre la novedad:
                            </string>
                            <TextareaAutosize
                              minRows={2}
                              value={novedades.novedad_detalle}
                              onChange={(e) =>
                                setNovedades({
                                  ...novedades,
                                  novedad_detalle: e.target.value,
                                })
                              }
                              className="my-2  w-100 p-2"
                              style={{
                                border: "1px solid #d1d1d1",
                                background: "#f5f5f5",
                              }}
                            />
                            {!novedades.isfromdb ? (
                              <button
                                className="shadow bg-dark text-white p-2 rounded"
                                onClick={() =>
                                  solicitudDeTransferenciaFichaFn()
                                }
                              >
                                <i className="fas fa-save"></i>
                                <label htmlFor="" className="mx-2">
                                  Guardar solicitud de transferencia
                                </label>
                              </button>
                            ) : (
                              <div>
                                <button
                                  className="shadow bg-danger text-white p-2 rounded"
                                  onClick={() =>
                                    cancelarsolicitudDeTransferenciaFichaFn()
                                  }
                                >
                                  <i className="fas fa-save"></i>
                                  <label htmlFor="" className="mx-2">
                                    Cancelar solicitud de transferencia
                                  </label>
                                </button>
                                <button
                                  className="shadow bg-dark text-white p-2 mx-2 rounded"
                                  onClick={() =>
                                    solicitudDeTransferenciaFichaFn()
                                  }
                                >
                                  <i className="fas fa-save"></i>
                                  <label htmlFor="" className="mx-2">
                                    Guardar solicitud de transferencia
                                  </label>
                                </button>
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              "Cargando ..."
            )}
          </div>
        </div>
      </ModalDefault>

      {/** MODAL REPORTE DE FICHA DEL CASO DE MANTENIMIENTO */}

      <ModalDefault
        bandera={"tecnicos"}
        modal={false}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Técnicos`}
        closeAction={() => {}}
      >
        <div className="row pb-4 ">
          {rol === "5" ? (
            <div className="col-md-6">
              <div className="border shadow rounded w-100 h-100 d-flex justifu-content-start align-items-start">
                <div className="w-100 text-center p-2 d-flex flex-column justify-content-start align-items-center">
                  {listadoTecnicos.length > 0 ? (
                    <div
                      className="w-100 d-flex flex-column"
                      style={{ backgroundColor: "#f5f5f5" }}
                    >
                      <div>
                        <h4>Técnicos disponibles</h4>
                        <button
                          onClick={() => {
                            setListadoTecnicos([]);
                          }}
                          className="text-white shadow"
                          style={{
                            border: "none",
                            borderRadius: "30px ",
                            background: "red",
                          }}
                        >
                          Cerrar ténicos
                        </button>
                        <button
                          onClick={() => {
                            setListadoTecnicos([]);
                          }}
                          className="text-white shadow"
                          style={{
                            border: "none",
                            borderRadius: "30px ",
                            background: "blue",
                          }}
                        >
                          Actualizar técnicos
                        </button>
                      </div>
                      <hr />
                      <table className="w-100">
                        <tbody
                          style={{
                            maxHeight: "10px",
                            minWidth: "0px",
                            overflowY: "scroll",
                          }}
                        >
                          {listadoTecnicos.map((data, index) => (
                            <tr
                              className="w-100 border d-flex justify-content-start align-items-center"
                              key={index}
                            >
                              <td className=" text-center d-flex w-100 justify-content-between align-items-center">
                                <strong>
                                  {" "}
                                  ({index + 1}) {data.infopersona.nombres}{" "}
                                  {data.infopersona.apellidos}
                                </strong>
                                <button
                                  onClick={() =>
                                    asignarTecnico(infoSeletedReporte, data)
                                  }
                                  style={{ background: "blue", color: "white" }}
                                  className="shadow border rounded m-1"
                                >
                                  Asignar
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <hr />
                    </div>
                  ) : null}
                  <h4 className="mt-2">Técnicos asignados</h4>
                  <hr />

                  <div className="w-100">
                    {listadoTecnicosAsiganados.length > 0 ? (
                      <table className="w-100">
                        <tbody
                          className="w-100"
                          style={{
                            maxHeight: "300px",
                            minWidth: "0px",
                            overflowY: "scroll",
                          }}
                        >
                          {listadoTecnicosAsiganados.map((data, index) => (
                            <tr className="w-100 border" key={index}>
                              <td className="w-100 text-center d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-row justy-content-center align-items-center">
                                  <div
                                    className="mx-2"
                                    style={{
                                      width: "30px ",
                                      borderRight: "1px solid black h-100",
                                      background: "blue",
                                      color: "white",
                                      fontSize: "18px",
                                      borderRadius: "30px",
                                    }}
                                  >
                                    {index + 1}
                                  </div>
                                  <div className="d-flex flex-column d-flex justify-content-start align-items-start">
                                    <h6>
                                      {
                                        data.mantenimiento_tecnicos.infopersona
                                          .nombres
                                      }{" "}
                                      {
                                        data.mantenimiento_tecnicos.infopersona
                                          .apellidos
                                      }
                                    </h6>
                                    <h6>
                                      <strong>Identificación: </strong>
                                      {
                                        data.mantenimiento_tecnicos.infopersona
                                          .identificacion
                                      }
                                    </h6>
                                  </div>
                                </div>
                                <div>
                                  <button
                                    style={{
                                      background: "red",
                                      color: "white",
                                    }}
                                    className="shadow border rounded m-1"
                                    onClick={() =>
                                      quitarTecnicoAsignado(data.mantecasig_id)
                                    }
                                  >
                                    Quitar
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="w-100 d-flex flex-column h-100 justify-content-center align-items-center">
                        <i
                          className="fa-regular fa-id-badge"
                          style={{ fontSize: "25px" }}
                        ></i>{" "}
                        <h5>No se asignaron técnicos a esta ficha</h5>
                      </div>
                    )}
                    <div className="d-flex w-100 flex-row justify-content-center align-items-center">
                      <button
                        onClick={() => {
                          getAllTecnicos();
                        }}
                        className="shadow border rounded m-2"
                        style={{
                          background: "blue",
                          color: "white",
                          borderRadius: "15px",
                        }}
                      >
                        Mostrar técnicos
                      </button>
                      <button
                        onClick={() => {
                          getAllTecnicosAsignados(infoSeletedReporte);
                        }}
                        className="shadow border rounded m-2"
                        style={{
                          background: "green",
                          color: "white",
                          borderRadius: "15px",
                        }}
                      ></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </ModalDefault>

      {/**MODAL ARCHIVOS */}
      <ModalDefault
        bandera={"archivos"}
        modal={showModal}
        fullscreen={true}
        icontitulo={<null />}
        titulo={`Archivos`}
        closeAction={onClosedModal}
      >
        <div className="row justify-content-center text-align-center ">
          {staticFilesTemp && staticFilesTemp.length > 0 ? (
            <DocViewer
              pluginRenderers={[...DocViewerRenderers, MyCustomMP4Renderer]}
              documents={staticFilesTemp}
              style={{
                height: "400px",
              }}
              language="es"
            />
          ) : (
            <p>No hay archivos</p>
          )}
        </div>
        <div className="d-flex justify-content-end flex-row align-self-start my-4">
          <button
            type=""
            onClick={onClosedModal}
            className="bg-btn-vet-danger sombra mx-1"
          >
            Cancelar
          </button>
          <button
            type=""
            onClick={onDowload}
            className="bg-btn-vet sombra mx-1"
          >
            <i className="fas fa-download mx-2"></i> Descargar todo
          </button>
        </div>
      </ModalDefault>
      {/** MODAL ARCHIVOS FIN */}

      {/** MODAL CREAR EDITAR TECNICOS  */}

      <ModalDefault
        bandera={"case"}
        modal={modalcase}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Nuevo técnico`}
        closeAction={() => {
          setModalCase(false);
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {!istecnico ? (
                <>
                  <div className="col-md-12 d-flex flex-column py-2">
                    <h5>PRIORIDAD</h5>
                    <select
                      name="prioridad"
                      value={stateCase.prioridad}
                      onChange={onchangecase}
                      style={{ height: "50px" }}
                    >
                      <option value="">NO DEFINIDO</option>
                      <option value="ALTA">ALTA</option>
                      <option value="MEDIA">MEDIA</option>
                      <option value="BAJA">BAJA</option>
                    </select>
                  </div>
                </>
              ) : null}

              <div className="col-md-6 d-flex flex-column py-3">
                <h5>Número O/T:</h5>
                <input
                  name="numero_ot"
                  type="text"
                  className="inputtext2 rounded "
                  onChange={onchangecase}
                  value={stateCase.numero_ot}
                />
              </div>

              <div className="col-md-6 d-flex flex-column py-3">
                <h5>Costo:</h5>
                <input
                  name="costo"
                  type="text"
                  className="inputtext2 rounded "
                  onChange={onchangecase}
                  value={stateCase.costo}
                />
              </div>

              <div className="col-md-12 d-flex flex-column py-3">
                <h5>Observación:</h5>
                <input
                  name="observacion"
                  type="text"
                  className="inputtext2 rounded "
                  onChange={onchangecase}
                  value={stateCase.observacion}
                />
              </div>

              <div className="col-md-12 d-flex flex-column py-3">
                <div className="w-100 d-flex justify-content-between align-items-center ">
                  <h5>Tipo defectos:</h5>
                  <button
                    className="shadow border p-2"
                    style={{
                      background: "black",
                      color: "white",
                      fontWeight: "bold",
                      borderRadius: "12px",
                    }}
                    onClick={() => {
                      setStateCase({
                        ...stateCase,
                        tipo_defecto: allDefectos,
                      });
                    }}
                  >
                    Cargar defectos configurados
                  </button>
                </div>
                <hr />
                <table className="w-100">
                  <thead className="w-100">
                    <tr>
                      <th>NOMBRE DEL TIPO</th>
                      <th className="text-center">SELECCIONADO</th>
                      <th>OPCIONES</th>
                    </tr>
                  </thead>
                  <tbody className="w-100 py-2">
                    {stateCase.tipo_defecto &&
                    stateCase.tipo_defecto.length > 0 ? (
                      stateCase.tipo_defecto.map((data, i) => (
                        <tr
                          key={i}
                          style={{ borderTop: "1px solid #a59696" }}
                          className=""
                        >
                          <td className="p-2 text-center">{data.nombre}</td>
                          <td className="text-center d-flex justify-content-center align-items-center">
                            <br />
                            <br />
                            <input
                              name="estado"
                              type="checkbox"
                              checked={data.estado}
                              onChange={() => onchangedefectos(data)}
                            />
                          </td>
                          <td className="text-center">
                            <div className="w-100"></div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className="w-100 d-flex justify-content-center align-items-center">
                        <td className="text-center" colSpan={4}>
                          No hay tipos de defectos registrados
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <button
                onClick={() => actualizarCaso()}
                className="shadow border rounded p-2 m-2 "
                style={{ color: "white", background: "black" }}
              >
                Actualizar caso
              </button>
            </div>
          </div>
        </div>
      </ModalDefault>
      {/** MODAL CREAR EDITAR TECNICOS FIN */}

      {/** MODAL FECHA PLANIFICADA DEL CASO */}
      <ModalDefault
        bandera={"fechaPlanificada"}
        modal={modalFechaPlanificada}
        size={"lg"}
        icontitulo={<null />}
        titulo={`Planificación`}
        closeAction={() => {
          setModalFechaPlanificada(false);
        }}
      >
        <div className="row px-4">
          <div className="col-md-12 d-flex flex-column py-3">
            <h5>
              Ingrese la fecha en la que deje estar ejecutado el mantenimeinto.
            </h5>

            <input
              name="fecha_planificada"
              type="date"
              className="inputtext2 rounded "
              onChange={(e) => {
                setFechaPlanificada({
                  fecha_planificada: e.target.value,
                  mantenimiento_case_id: selectedCase.mantenimiento_case_id,
                });
              }}
              value={fechaplanificada.fecha_planificada}
            />
            <div className="col-md-12 py-3">
              <button
                className="w-100 p-2 d-flex flex-row justify-content-center align-items-center"
                style={{
                  borderRadius: "12px",
                  background: "black",
                  color: "white",
                }}
                onClick={() => guardarFechaPlanificadaFn()}
              >
                <i
                  className="fas fa-save mx-2"
                  style={{ fontSize: "18px" }}
                ></i>
                <label htmlFor="">Guardar cambios</label>
              </button>
            </div>
          </div>
        </div>
      </ModalDefault>
      {/** MODAL FECHA PLANIFICADA DEL  CASO */}

      {/** MODAL CERRAR CASO */}
      <ModalDefault
        bandera={"cierreCaso"}
        modal={modalCierreCaso}
        size={"lg"}
        icontitulo={<null />}
        titulo={`CONFIRMACIÓN`}
        closeAction={() => {
          setModalCierreCaso(false);
        }}
      >
        <div className="d-flex justify-content-center flex-column align-items-center">
          <h5 className="my-3">SE FINALIZARÁ EL MANTENIMINETO</h5>
          <div className="d-flex flex-row">
            <button
              className="mx-1 btn btn-primary"
              style={{
                background: "red",
                color: "white",
                borderRadius: "15px",
              }}
              onClick={() => {
                setCerrarCaso({
                  ...cerrarCaso,
                  mantenimiento_case_id: "",
                });
                setSelectedCase(null);
                setModalCierreCaso(false);
              }}
            >
              Cancelar
            </button>
            <button
              className="mx-1 btn btn-primary"
              style={{
                background: "green",
                color: "white",
                borderRadius: "15px",
              }}
              onClick={() => cerrarCasoFn()}
            >
              Confirmar
            </button>
          </div>
        </div>
      </ModalDefault>
      {/** MODAL CERRAR CASO FIN  */}
    </div>
  );
};

export default MantenimientoTable;
