import React from "react";

export const BotonesCampoTabla = ({ row, acciones, handleMenuAction }) => {
	return (
		<div className="w-100 d-flex justify-content-end align-items-center flex-row w-100">
			{acciones.map((accion, key) => (
				<div key={key} className="px-1">
					<button
						className={`btn btn-${COLOR[accion]} btn-sm`}
						onClick={() => handleMenuAction(accion, row)}
					>
						<i
							className={ICON2[accion] + " text-white"}
							style={{ fontWeight: "bold", fontSize: "15px" }}
						></i>
					</button>
				</div>
			))}
		</div>
	);
};

export const ICON = {
	delete: "bi bi-trash",
	edit: "bi bi-pencil",
	activar: "bi bi-check",
	add: "bi bi-plus",
	ver: "bi bi-eye",
	changuePassword: "bi bi-key",
};
const ICON2 = {
	delete: "bi bi-trash",
	edit: "bi bi-pencil",
	activar: "bi bi-check",
	add: "bi bi-plus",
	ver: "bi bi-eye",
	changuePassword: "bi bi-key",
	register: "bo bi-floppy",
	hora: "bi bi-clock",
};
const COLOR = {
	delete: "danger",
	edit: "warning",
	activar: "success",
	register: "secondary",
	add: "primary",
	ver: "info",
	changuePassword: "secondary",
	hora: "primary",
};
