import { useEffect, useState } from "react"; // Usar sheetjs-style para estilos avanzados
/* import * as XLSX from "xlsx"; */
/* import  XLSX  from "sheetjs-style"; */ import XLSX from "xlsx-js-style";

import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import ReactSelect from "react-select";
import makeAnimated from "react-select/animated";

import {
  ApiGetMantenimientoCase,
  ApiGetTotalMantenimientoFichas,
} from "../../../api/formularios";
import MantenimientoTable from "../../../components/admin/mantenimientos/listarMantenimientos";
import dayjs from "dayjs";
import ModalDefault from "../../../components/modalDefault";
import CrearEditarTecnicos from "../../../components/admin/mantenimientos/crearEditarTecnicos";
import {
  ApiGetAllTecnicos,
  ApicrearDefecto,
  ApiEditarTiposDefectos,
  ApiEliminarTiposDefectos,
  ApiObtenerTiposDefectos,
} from "../../../api/tecnicos";
import { toasterror, toastsuccess } from "../../../helpers/toast1";
import WhatsAppMenu from "./menu";
const Mantenimientos = ({ rol, session, istecnico = false }) => {
  //estados para controlar los colores de los filtros
  const animatedComponents = makeAnimated();

  const [aplicafiltrogeneral, setAplicafiltrogeneral] = useState(false);
  const [aplicafiltroplanificacion, setAplicafiltroplanificacion] = useState(
    false
  );
  //cierre de estados para controlar los filtros
  const [filtroAvanzado, setfiltroAvanzado] = useState(false);
  const [listTecnicosFilter, setListTecnicosFilter] = useState([]);
  const [filtroAvanzadoporreporte, setfiltroAvanzadoporreporte] = useState(
    false
  );

  const [statedefecto, setStateDefecto] = useState({
    nombre: "",
    estado: true,
    tipo_defecto_id: "",
  });
  const [novedades, setNovedades] = useState({
    novedad: false,
    novedad_detalle: "",
    pre_ficha_id: "",
  });

  const [filtrorangos, setfiltrorangos] = useState(false);
  const [filtronulos, setfiltronulos] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const [allDefectos, setAllDefectos] = useState([]);
  const [modaldefectos, setModalDefectos] = useState(false);
  const [listadoTecnicos, setListadoTecnicos] = useState([]);

  const [modalTecnico, setModalTecnico] = useState(false);
  const [modalListarTecnico, setModalListarTecnico] = useState(false);

  const [selecteddataTabla, setSelectedDataTabla] = useState("TOTAL");
  const [pageSize, setPageSize] = useState(350);
  const [mantenimientos, setMantenimientos] = useState([]);
  const [estados, setEstados] = useState({
    FINALIZADO: [],
    PENDIENTE: [],
    PLANIFICADO: [],
  });
  const [totalStados, setTotalEstados] = useState([]);
  const [dataTabla, setDataTabla] = useState([]);
  const [dateFilter, setDateFilter] = useState([
    dayjs().subtract(1, "month").toDate(),
    dayjs().toDate(),
  ]);
  const [prioridadfilteravance, setPrioridadfilteravance] = useState([
    {
      checked: false,
      prioridad: "ALTA",
      bg: "red",
      color: "white",
    },
    {
      checked: false,
      prioridad: "MEDIA",
      bg: "yellow",
      color: "black",
    },
    {
      checked: false,
      prioridad: "BAJA",
      bg: "blue",
      color: "white",
    },
    {
      checked: false,
      prioridad: null,
      bg: "grey",
      color: "white",
    },
  ]);
  const [estadofilteravance, setestadofilteravance] = useState([
    {
      checked: false,
      estado: "FINALIZADO",
      bg: "green",
      color: "white",
    },
    {
      checked: false,
      estado: "PLANIFICADO",
      bg: "#ffc107",
      color: "black",
    },
    {
      checked: false,
      estado: "PENDIENTE",
      bg: "red",
      color: "white",
    },
  ]);
  const [modalexcel, setModalExcel] = useState(false);
  const [camposDisponibles, setCamposDisponibles] = useState([
    "ficha",
    "quienreporta",
    "fecha_reporte",
    "tecnicos",
    "fecha_planificada",
    "prioridad",
    "tipo_defecto",
    "numero_ot",
    "costo",
    "observacion",
    "estado",
    "fecha_cierre",
  ]);
  const [camposSeleccionados, setCamposSeleccionado] = useState(
    camposDisponibles
  );
  const [generateModelExcel, setGenerateModelExcel] = useState([]);

  const [caducados, setCaducados] = useState([]);
  const [xcaducar, setXcaducar] = useState([]);
  const [solicitudesTranferencias, setSolicitudesTranferencias] = useState([]);

  /*   useEffect(() => {
    const data = JSON.parse(localStorage.getItem("datauser"));
    setSesion(data);
  }, []); */

  /* useEffect(() => {
    const interval = setInterval(() => {
      if (session) {
        getAllMantenimientos();
      }
    }, 5 * 60 * 1000); // 5 minutes

    return () => clearInterval(interval);
  }, [session]); */

  const filtrarPorCaducidad = (registros, diasAntesDeCaducar) => {
    const hoy = new Date();
    const rangoCaducidad = new Date();
    rangoCaducidad.setDate(hoy.getDate() + diasAntesDeCaducar);
    return registros.filter((registro) => {
      const fechaCaducidad = new Date(
        registro.mantenimientos_case.fecha_planificada
      );
      if (fechaCaducidad >= hoy && fechaCaducidad <= rangoCaducidad) {
        return registro;
      } else return null;
    });
  };

  const getAllMantenimientos = async () => {
    console.log("iniciando mantenimientos");
    /* const inicio = dayjs(dateFilter[0]).format("DD-MM-YYYY");
    const fin = dayjs(dateFilter[1]).format("DD-MM-YYYY");
    {
      fecha_inicio: inicio,
      fecha_fin: fin,
    }
    */
    const resttotal = await ApiGetTotalMantenimientoFichas();
    if (resttotal.sms === "ok") {
      let auxdata = [];

      const totalPages = Math.ceil(resttotal.data / pageSize);

      for (let i = 1; i <= totalPages; i++) {
        const { data: fichas2, sms } = await ApiGetMantenimientoCase({
          /*  fecha_inicio: inicio,
            fecha_fin: fin, */
          page: i,
          pageSize: pageSize,
        });
        if (sms === "ok") {
          auxdata = auxdata.concat(fichas2);
        }
      }

      console.log(auxdata);

      if (istecnico) {
        auxdata = await filtrarSoloDatosdelTecnicoFn(auxdata);
      }

      const auxdataSinTransferencias = await getDataSinTranferencias(auxdata);

      /*  const groupedData = await groupByField(
          auxdataSinTransferencias,
          "mantenimientos_case"
        ); */
      setMantenimientos(auxdata);
      /* console.log(groupedData);
        setEstados(groupedData);
        setTotalEstados(auxdataSinTransferencias); */
      actualizarestadofn(auxdataSinTransferencias);

      if (selecteddataTabla === "TOTAL") {
        setDataTabla(auxdataSinTransferencias);
      }
    } else {
      toasterror(resttotal.sms);
    }
    limpiarFiltros();
    setTimeout(getAllTecnicos, 2000);
  };

  const getDataSinTranferencias = async (auxdata) => {
    const auxdataSinTransferencias = await auxdata.filter((data) => {
      if (data.mantenimientos_case.estado_actividad !== "TRANSFERENCIA") {
        return data;
      }
    });
    return auxdataSinTransferencias;
  };

  const limpiarFiltros = () => {
    setStartDate("");
    setEndDate("");
    setStartDateplanificada("");
    setEndDateplanificada("");
    setAplicafiltroplanificacion(false);
    setAplicafiltrogeneral(false);
    limpiarlistadotecnicosfilteravance();
    limpiarestadosfilteravance();
    limpiarprioridadfilteravance();
  };

  const limpiarlistadotecnicosfilteravance = async () => {
    const res = await listadoTecnicos.map((data) => {
      data.checked = false;
      return data;
    });

    setListadoTecnicos(res);
  };

  const limpiarestadosfilteravance = async () => {
    const res = await estadofilteravance.map((data) => {
      data.checked = false;
      return data;
    });

    setestadofilteravance(res);
  };
  const limpiarprioridadfilteravance = async () => {
    const res = await prioridadfilteravance.map((data) => {
      data.checked = false;
      return data;
    });

    setPrioridadfilteravance(res);
  };

  const getAlertas = async () => {
    const restransferencias = await mantenimientos.filter(
      (datax) => datax.mantenimientos_case.estado_actividad === "TRANSFERENCIA"
    );
    setSolicitudesTranferencias(restransferencias);
  };

  const getAlertasxcaducar = async () => {
    const registrosPorCaducar = await filtrarPorCaducidad(dataTabla, 5);
    setXcaducar(registrosPorCaducar);

    /* const resxcaducas=await data.filter((datax)=>dayjs(datax.mantenimientos_case.fecha_planificada).format("YYYY-MM-DD") ) */
  };
  const filtrarSoloDatosdelTecnicoFn = async (datamantenimientos) => {
    let auxdata = [];

    if (datamantenimientos.length === 0) return [];

    for (let index = 0; index < datamantenimientos.length; index++) {
      const verify =
        datamantenimientos[index].mantenimientos_case
          .mantenimiento_tecnicos_asignaciones;
      if (verify.length > 0) {
        for (let i = 0; i < verify.length; i++) {
          if (
            session.usuario === verify[i].mantenimiento_tecnicos.identificacion
          ) {
            if (auxdata.length > 0) {
              const resf = auxdata.filter(
                (datafilter) =>
                  datafilter.pre_ficha_id ===
                  datamantenimientos[index].pre_ficha_id
              );
              if (resf.length === 0) {
                auxdata.push(datamantenimientos[index]);
              }
            } else {
              auxdata.push(datamantenimientos[index]);
            }
          }
        }
      }
    }

    return auxdata;
  };

  const groupByField = (array, field) => {
    return array.reduce((grouped, item) => {
      const key = item[field].estado;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(item);
      return grouped;
    }, {});
  };

  useEffect(() => {
    if (aplicafiltrogeneral && mantenimientos.length > 0) {
      handleFilter();
    } else {
      getAllMantenimientos();
    }
  }, [istecnico, aplicafiltrogeneral]);

  useEffect(() => {
    if (dataTabla.length > 0) {
      setTimeout(() => {
        getAlertasxcaducar();
        getAlertas();
      }, 1000);
    }
  }, [dataTabla]);

  const getAllTecnicos = async () => {
    const res = await ApiGetAllTecnicos();
    if (res.sms === "ok") {
      /* const options = res.data.map((item) => {
        return {
          value: item.mantecnico_id,
          label: `${item.infopersona.nombres} ${item.infopersona.apellidos}`,
        };
      }); */
      setListadoTecnicos(res.data);
      /*       setListTecnicosFilter(options);
       */
    } else {
      toasterror(res.mensaje);
    }
  };

  useEffect(() => {
    allDefectosFn();
  }, []);

  const onchangedefecto = (e) => {
    if (e.target.name === "estado") {
      setStateDefecto({
        ...statedefecto,
        estado: !statedefecto.estado,
      });
    } else {
      setStateDefecto({
        ...statedefecto,
        [e.target.name]: e.target.value,
      });
    }
  };

  const allDefectosFn = async () => {
    const res = await ApiObtenerTiposDefectos();
    if (res.sms === "ok") {
      setAllDefectos(res.data);
    } else {
      toasterror(res.mensaje);
    }
  };

  const guardarDefectoFn = async () => {
    if (statedefecto.nombre === "") {
      toasterror("El campo nombre no debe estar vacío");
      return;
    }

    const res = await ApicrearDefecto(statedefecto);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setStateDefecto({
        ...statedefecto,
        nombre: "",
        estado: false,
        tipo_defecto_id: "",
      });
      allDefectosFn();
    } else {
      toasterror(res.mensaje);
    }
  };

  const editarDefectoFn = async () => {
    if (statedefecto.nombre === "") {
      toasterror("El campo nombre no debe estar vacío");
      return;
    }

    if (statedefecto.tipo_defecto_id === "") {
      toasterror(
        "no se ah identificado correctamente el tipo de defecto contacte a soporte"
      );
    }
    const res = await ApiEditarTiposDefectos(statedefecto);
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setStateDefecto({
        ...statedefecto,
        nombre: "",
        estado: false,
        tipo_defecto_id: "",
      });
      allDefectosFn();
    } else {
      toasterror(res.mensaje);
    }
  };

  const eliminarDefectoFn = async (tipo_defecto_id) => {
    const res = await ApiEliminarTiposDefectos({ tipo_defecto_id });
    if (res.sms === "ok") {
      toastsuccess(res.mensaje);
      setStateDefecto({
        ...statedefecto,
        nombre: "",
        estado: false,
        tipo_defecto_id: "",
      });
      allDefectosFn();
    } else {
      toasterror(res.mensaje);
    }
  };

  const validaSession = () => {
    if (!session?.roles.code) {
      toasterror(
        "No hay sessión que podamos verificar,comuniquese con soporte"
      );
      return false;
    }
    if (session?.roles.code !== "5") {
      toasterror("No tienes permiso para realizar esta acción");
      return false;
    }

    return true;
  };

  //filtros por fechas_planificadas
  //=======================================================================================
  const [startDateplanificada, setStartDateplanificada] = useState("");
  const [endDateplanificada, setEndDateplanificada] = useState("");

  const handleFilterPlanificado = async () => {
    // If no dates are selected, show all data
    if (!startDateplanificada && !endDateplanificada) {
      toasterror("Por favor, selecciona ambas fechas");
      /* setDebug({
        startDate: "none",
        endDate: "none",
        matches: ["all"],
      }) */
      return;
    }

    // For debugging
    const debugMatches = [];

    // Filter the data based on date range
    let filtered = mantenimientos.filter((item) => {
      const itemDate = dayjs(
        item.mantenimientos_case.fecha_planificada
      ).startOf("day");
      const startDateObj = startDateplanificada
        ? dayjs(startDateplanificada).startOf("day")
        : null;
      const endDateObj = endDateplanificada
        ? dayjs(endDateplanificada).startOf("day")
        : null;

      // Check if item date is after or equal to start date (if provided)
      const isAfterStart =
        !startDateObj ||
        itemDate.isAfter(startDateObj) ||
        itemDate.isSame(startDateObj);

      // Check if item date is before or equal to end date (if provided)
      const isBeforeEnd =
        !endDateObj ||
        itemDate.isBefore(endDateObj) ||
        itemDate.isSame(endDateObj);

      // For debugging
      if (isAfterStart && isBeforeEnd) {
        debugMatches.push({
          id: item.id,
          date: itemDate.format("YYYY-MM-DD"),
          matches: {
            afterStart: isAfterStart,
            beforeEnd: isBeforeEnd,
          },
        });
      }

      return isAfterStart && isBeforeEnd;
    });

    filtered = ordenar(filtered);
    /*setDataTabla(filtered);
    const groupedData = groupByField(filtered, "mantenimientos_case");
    setEstados(groupedData);
    setTotalEstados(filtered); */

    return filtered;
    /* setDebug({
      startDate: startDate ? dayjs(startDate).format("YYYY-MM-DD") : "none",
      endDate: endDate ? dayjs(endDate).format("YYYY-MM-DD") : "none",
      matches: debugMatches,
    }) */
  };

  //fin filtros por fechas planificadas
  //=======================================================================================

  //filtros por fechas
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sortOrder, setSortOrder] = useState("desc");
  const [modalListarTecnicoFiltros, setModalListarTecnicoFiltros] = useState(
    false
  );

  //==========================
  const handleFilter = () => {
    if (startDate && endDate) {
      const start = dayjs(startDate);
      const end = dayjs(endDate).endOf("day"); // Final del día

      let filtered = mantenimientos.filter((item) => {
        const timestamp = dayjs(item.created_at);
        return timestamp.isAfter(start) && timestamp.isBefore(end);
      });
      filtered = ordenar(filtered);
      actualizarestadofn(filtered);
    } else {
      toasterror("Por favor, selecciona ambas fechas y horas");
    }
  };

  const actualizarestadofn = (filtered) => {
    const groupedData = groupByField(filtered, "mantenimientos_case");
    setDataTabla(filtered);
    setEstados(groupedData);
    setTotalEstados(filtered);
  };

  const ordenar = (filtered) => {
    // Ordenar los datos teniendo en cuenta las horas
    filtered = filtered.sort((a, b) => {
      const dateA = dayjs(a.created_at);
      const dateB = dayjs(b.created_at);
      return sortOrder === "asc" ? dateA.diff(dateB) : dateB.diff(dateA);
    });

    return filtered;
  };

  const [aplicarFechaPlanificacion, setAplicarfechaplanificacion] = useState(
    false
  );
  const aplicarfiltrotecnicosFn = async () => {
    const restecnicos = await listadoTecnicos.filter((data) => data.checked);
    const resprioridad = await prioridadfilteravance.filter(
      (data) => data.checked
    );
    const resestado = await estadofilteravance.filter((data) => data.checked);
    let auxdata = mantenimientos;
    let aux2 = mantenimientos;

    if (restecnicos.length > 0) {
      auxdata = recorridoFiltrosTecnicos(restecnicos, auxdata);
    }

    if (resprioridad.length > 0) {
      auxdata = await recorridoFiltrosPrioridad(resprioridad, auxdata);
      //auxdata = recorridoFiltrosEstados(resestado, auxdata);
    }

    if (resestado.length > 0) {
      auxdata = await recorridoFiltrosEstado(resestado, auxdata);
      //auxdata = recorridoFiltrosEstados(resestado, auxdata);
    }

    if (filtronulos && !filtrorangos) {
      auxdata = await auxdata.filter(
        (data) => data.mantenimientos_case.fecha_planificada === null
      );
    }

    console.log(filtronulos, "nulos");
    console.log(filtrorangos, "rangos");

    if (filtrorangos && !filtronulos) {
      auxdata = await handleFilterPlanificado();
    }
    console.log(resprioridad.length, "prioridad");
    console.log(restecnicos.length, "tecnicos");
    console.log(resestado.length, "estados");

    if (
      resestado.length === 0 &&
      resprioridad.length === 0 &&
      restecnicos.length === 0 &&
      filtronulos === false &&
      filtrorangos === false
    ) {
      console.log("si cumple");
      setDataTabla(aux2);
    } else {
      console.log("esta raro esto");
    }

    setDataTabla(auxdata);
  };

  useEffect(() => {
    if (dataTabla.length > 0) {
      aplicarfiltrotecnicosFn();
    }
  }, [filtronulos]);

  const recorridoFiltrosTecnicos = (res, info) => {
    console.log(res, "los que deberia traer");
    let auxdata = [];
    for (let index1 = 0; index1 < res.length; index1++) {
      for (let index = 0; index < info.length; index++) {
        const verify =
          info[index].mantenimientos_case.mantenimiento_tecnicos_asignaciones;
        const transferencia = info[index].mantenimientos_case.estado_actividad;
        if (verify.length > 0 && transferencia !== "TRANSFERENCIA") {
          for (let i = 0; i < verify.length; i++) {
            if (
              res[index1].identificacion ===
              verify[i].mantenimiento_tecnicos.identificacion
            ) {
              if (auxdata.length > 0) {
                const resf = auxdata.filter(
                  (datafilter) =>
                    datafilter.pre_ficha_id === info[index].pre_ficha_id
                );
                if (resf.length === 0) {
                  auxdata.push(info[index]);
                }
              } else {
                auxdata.push(info[index]);
              }
            }
          }
        }
      }
    }
    return auxdata;
  };
  const recorridoFiltrosPrioridad = (res, info) => {
    console.log(res, "lo q debeeria traer de la prioridad");
    console.log(info, "lo q debeeria verificar");

    let auxdata = [];
    for (let index1 = 0; index1 < res.length; index1++) {
      for (let index = 0; index < info.length; index++) {
        const verify = info[index].mantenimientos_case.prioridad;
        const transferencia = info[index].mantenimientos_case.estado_actividad;
        if (transferencia !== "TRANSFERENCIA") {
          if (verify === res[index1].prioridad) auxdata.push(info[index]);
        }
      }
    }
    return auxdata;
  };
  const recorridoFiltrosEstado = (res, info) => {
    console.log(res, "lo q debeeria traer de la prioridad");
    console.log(info, "lo q debeeria verificar");

    let auxdata = [];
    for (let index1 = 0; index1 < res.length; index1++) {
      for (let index = 0; index < info.length; index++) {
        const verify = info[index].mantenimientos_case.estado;
        const transferencia = info[index].mantenimientos_case.estado_actividad;
        if (transferencia !== "TRANSFERENCIA") {
          if (verify === res[index1].estado) auxdata.push(info[index]);
        }
      }
    }
    return auxdata;
  };
  const filtrarPorFichaID = (e) => {
    const res = mantenimientos.filter(
      (data) =>
        `${data.pre_ficha_id}`.includes(e.target.value) ||
        data.nombres.toUpperCase().includes(e.target.value.toUpperCase())
    );
    setDataTabla(res);
  };

  const filtrarPorCriterio = async (e) => {
    let restotal = [];
    for (let index = 0; index < mantenimientos.length; index++) {
      if (mantenimientos[index].pre_fichas_preguntas.length > 0) {
        for (
          let i = 0;
          i < mantenimientos[index].pre_fichas_preguntas.length;
          i++
        ) {
          const { pre_pregunta_id, respuesta } = mantenimientos[
            index
          ].pre_fichas_preguntas[i].pre_preguntas;

          if (pre_pregunta_id === 9) {
            if (mantenimientos[index].pre_fichas_preguntas[i].respuesta) {
              let textoLimpio =
                mantenimientos[index].pre_fichas_preguntas[i].respuesta;
              if (
                textoLimpio.toLowerCase().includes(e.target.value.toLowerCase())
              ) {
                restotal.push(mantenimientos[index]);
              }
            }
          }
        }
      }
    }

    setDataTabla(restotal);
  };

  const showAlertad = (bandera) => {
    console.log(bandera);
    if (bandera === "xcaducar") {
      setDataTabla(xcaducar);
      return;
    }
    if (bandera === "transferencias") {
      setDataTabla(solicitudesTranferencias);
      return;
    }
  };

  const activarModalexcel = async () => {
    setModalExcel(true);
  };

  const generarStruturaDescargaExcel = async () => {
    const res = await Promise.all(
      dataTabla.map(async (data) => {
        const auxdata = {};
        for (let index = 0; index < camposSeleccionados.length; index++) {
          if (camposSeleccionados[index] === "tecnicos") {
            auxdata[camposSeleccionados[index]] = await listarTecnicosExcel(
              data.mantenimientos_case.mantenimiento_tecnicos_asignaciones
            );
          } else if (camposSeleccionados[index] === "tipo_defecto") {
            auxdata[
              camposSeleccionados[index]
            ] = await listarTipoDefectossExcel(
              data.mantenimientos_case.tipo_defecto
            );
          } else if (camposSeleccionados[index] === "fecha_reporte") {
            auxdata[camposSeleccionados[index]] = data.created_at
              ? dayjs(data.created_at).format("DD-MM-YYYY")
              : "- s/n -"; // Si es null, retornará un string vacío
          } else if (camposSeleccionados[index] === "fecha_planificada") {
            auxdata[camposSeleccionados[index]] = data.created_at
              ? dayjs(data.fecha_planificada).format("DD-MM-YYYY")
              : "- s/n -"; // Si es null, retornará un string vacío
          } else if (camposSeleccionados[index] === "quienreporta") {
            auxdata[camposSeleccionados[index]] = data.nombres ?? "- s/n -";
          } else if (camposSeleccionados[index] === "ficha") {
            auxdata[camposSeleccionados[index]] = data.pre_ficha_id;
          } else if (camposSeleccionados[index] === "fecha_cierre") {
            auxdata[camposSeleccionados[index]] = data.mantenimientos_case
              .fecha_cierre
              ? dayjs(data.mantenimientos_case.fecha_cierre).format(
                  "DD-MM-YYYY HH:MM"
                )
              : "- s/n -";
          } else {
            auxdata[camposSeleccionados[index]] =
              data.mantenimientos_case[`${camposSeleccionados[index]}`] ??
              "- s/n -";
          }
        }

        const objetoReordenado = reordenarObjeto(auxdata, camposSeleccionados);

        return objetoReordenado;
      })
    );
    return res;
  };

  const listarTecnicosExcel = async (tecnicos) => {
    if (tecnicos.length > 0) {
      const res = tecnicos
        .map(
          (data) =>
            `${data.mantenimiento_tecnicos.infopersona.nombres} ${data.mantenimiento_tecnicos.infopersona.apellidos}`
        )
        .join(" ./* ");
      return `${res}`;
    } else {
      return "- s/n -";
    }
  };
  const listarTipoDefectossExcel = async (defectos) => {
    if (defectos && defectos.length > 0) {
      const res = defectos
        .map((data) => {
          if (data.activo) {
            return data.nombre;
          }
        })
        .join(" ./* ");
      return `${res}`;
    } else {
      return "- s/n -";
    }
  };

  const reordenarObjeto = (objeto, orden) => {
    const nuevoObjeto = {};

    // Agregar las propiedades en el orden deseado
    orden.forEach((clave) => {
      if (objeto.hasOwnProperty(clave)) {
        nuevoObjeto[clave] = objeto[clave];
      }
    });

    // Agregar las propiedades faltantes al final
    Object.keys(objeto).forEach((clave) => {
      if (!nuevoObjeto.hasOwnProperty(clave)) {
        nuevoObjeto[clave] = objeto[clave];
      }
    });

    return nuevoObjeto;
  };

  const descargarExcel = async (res) => {
    if (res.length === 0) {
      toasterror("No hay campos seleccionados para generar el excel");
      return;
    }

    const resdata = await generarStruturaDescargaExcel();
    const workbook = XLSX.utils.book_new();
    if (res.length === 0) {
      toasterror("No hay datos que descargar");
      return;
    }

    const ws = XLSX.utils.json_to_sheet(resdata);
    // Aplicar estilos de alineación

    // Ajustar el ancho de las columnas
    /*     const colWidths = camposSeleccionados.map((column) => {
      return Math.max(
        ...resdata.map((row) => row[column]?.length || 0),
        column.length
      );
    }); */

    /*     worksheet["!cols"] = colWidths.map((w) => ({ wch: w }));
    Object.keys(worksheet).forEach((celda) => {
      if (!celda.startsWith("!")) {
        worksheet[celda].s = {
          alignment: {
            horizontal: "center", // Centrar horizontalmente
            vertical: "center", // Centrar verticalmente
          },
        };
      }
    }); */

    // Obtener el rango de la hoja
    const range = XLSX.utils.decode_range(ws["!ref"]);

    // Aplicar estilos solo a la cabecera (primera fila)
    for (let C = range.s.c; C <= range.e.c; ++C) {
      const cell_address = { c: C, r: range.s.r }; // Solo la primera fila (r: 0)
      const cell_ref = XLSX.utils.encode_cell(cell_address);
      if (!ws[cell_ref]) continue;

      // Estilo para la cabecera
      ws[cell_ref].s = {
        fill: { fgColor: { rgb: "3498db" } }, // Color de fondo #3498db
        font: { bold: true, color: { rgb: "FFFFFF" } }, // Texto en negrita y color blanco
        alignment: { horizontal: "center", vertical: "center", wrapText: true }, // Centrar el texto y permitir ajuste
        border: {
          top: { style: "thin", color: { rgb: "000000" } },
          bottom: { style: "thin", color: { rgb: "000000" } },
          left: { style: "thin", color: { rgb: "000000" } },
          right: { style: "thin", color: { rgb: "000000" } },
        },
      };
    }

    // Calcular el ancho de las columnas basado en el contenido más largo (encabezado o registros)
    const colWidths = [];
    for (let C = range.s.c; C <= range.e.c; ++C) {
      let maxLength = 0;

      // Verificar el encabezado
      const headerCell = ws[XLSX.utils.encode_cell({ c: C, r: range.s.r })];
      if (headerCell && headerCell.v) {
        maxLength = headerCell.v.toString().length;
      }

      // Verificar los registros
      for (let R = range.s.r + 1; R <= range.e.r; ++R) {
        const cell = ws[XLSX.utils.encode_cell({ c: C, r: R })];
        if (cell && cell.v) {
          const cellLength = cell.v.toString().length;
          if (cellLength > maxLength) {
            maxLength = cellLength;
          }
        }
      }

      // Ajustar el ancho de la columna (agregar un poco de espacio extra)
      colWidths.push({ wch: maxLength + 1 }); // +2 para un poco de espacio extra
    }
    ws["!cols"] = colWidths; // Aplicar los anchos de columna

    // Añadir filtros
    ws["!autofilter"] = { ref: XLSX.utils.encode_range(range) };

    XLSX.utils.book_append_sheet(workbook, ws, "TablaDatos");
    XLSX.writeFile(workbook, `Reporte mantenimiento.xlsx`);
  };

  // Handle reordering within and between lists
  const handleDragEnd = (result) => {
    if (!result.destination) return; // Si no hay destino, no hacer nada

    const reorderedItems = Array.from(camposSeleccionados);
    const [reorderedItem] = reorderedItems.splice(result.source.index, 1); // Remueve el ítem arrastrado
    reorderedItems.splice(result.destination.index, 0, reorderedItem); // Lo inserta en la nueva posición

    setCamposSeleccionado(reorderedItems); // Actualiza el estado
  };

  const [selectedbutton, setSelectedButton] = useState("TOTAL");
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenfilterReports, setIsOpenFilterReports] = useState(false);

  const formatearfiltroavanzado = async () => {
    const res = await listadoTecnicos.map((data) => {
      data.checked = false;
      return data;
    });
    setListadoTecnicos(res);
  };

  const getMenu = () => {
    return (
      <div className="row px-0 py-2 m-0">
        <div className="col-md-12 d-flex justify-content-between align-items-center flex-row">
          <button
            onClick={() => activarModalexcel()}
            style={{ background: "green", color: "white" }}
            className="dropdown-btn-mc  p-2 shadow mx-1"
          >
            <i className="fas fa-table-cells" style={{ fontSize: "20px" }}></i>
          </button>
          <button
            onClick={() => {
              setfiltroAvanzado(false);
              setfiltroAvanzadoporreporte(!filtroAvanzadoporreporte);
              formatearfiltroavanzado();
            }}
            style={{ background: "#3f3f3f", color: "white" }}
            className="dropdown-btn-mc  p-2 shadow mx-1"
          >
            <span>
              <i className="fas fa-filter"></i>
              <label htmlFor=""> Por fichas </label>
            </span>
          </button>
          <button
            onClick={() => {
              setfiltroAvanzadoporreporte(false);
              setfiltroAvanzado(!filtroAvanzado);
            }}
            style={{ background: "#3f3f3f", color: "white" }}
            className="dropdown-btn-mc  p-2 shadow mx-1"
          >
            <span>
              <i className="fas fa-filter"></i>
              <label htmlFor="">Avanzado</label>
            </span>
          </button>
          {/*  {filtrotecnicosFn()} */}
          <div className="dropdown-mc ">
            {istecnico ? null : (
              <button
                className="dropdown-btn-mc  shadow  p-2 m-1"
                style={{ background: "black" }}
                onClick={() => setIsOpen(!isOpen)}
              >
                <span className="icon mx-2">{!isOpen ? "☰" : "X"}</span>
              </button>
            )}
            {/* <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-row">
              <button
                onClick={() => {
                  getAllMantenimientos();
                }}
                className="w-100 shadow rounded mx-1 btn mt-2 border btn-acciones "
                style={{
                  borderColor: "#3476fe",
                  background: "#3476fe",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <i className="fas fa-rotate"></i>
                <hr />
                <strong>Actualizar registros</strong>
              </button>
              <button
                onClick={() => {
                  if (!validaSession()) return;
                  allDefectosFn();
                  setModalDefectos(true);
                }}
                className="w-100 shadow rounded mx-1 btn mt-2 border btn-acciones "
                style={{
                  borderColor: "black",
                  background: "black",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <i className="fas fa-cog"></i>
                <hr />
                <strong>Configurar defectos</strong>
              </button>
              <button
                onClick={() => {
                  if (!validaSession()) return;

                  getAllTecnicos();
                  setModalListarTecnico(true);
                }}
                className="w-100 shadow rounded mx-1 btn mt-2 border btn-acciones "
                style={{
                  borderColor: "black",
                  background: "black",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <i className="fas fa-list"></i>
                <hr />
                <strong>Listado de técnicos</strong>
              </button>
              <button
                onClick={() => {
                  if (!validaSession()) return;
                  setModalTecnico(true);
                }}
                className="w-100 shadow rounded mx-1 btn mt-1 border btn-acciones"
                style={{
                  borderColor: "black",
                  background: "black",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <i className="fas fa-user-plus"></i>
                <hr />
                <strong>Nuevo técnico</strong>
              </button>
              <button
                onClick={() => activarModalexcel()}
                className="w-100 shadow rounded mx-1 btn mt-1 border btn-acciones"
                style={{
                  borderColor: "black",
                  background: "green",
                  color: "white",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                <i className="fas fa-table-cells"></i>
                <hr />
                <label htmlFor="">Generar excel</label>
              </button>
            </div> */}
            {isOpen && (
              <div className="dropdown-content-mc">
                <button
                  onClick={() => {
                    if (!validaSession()) return;
                    allDefectosFn();
                    setModalDefectos(true);
                  }}
                >
                  <i className="fas fa-cog"></i>TIPOS DE DEFECTOS
                </button>
                <button onClick={() => alert("Opción 2 seleccionada")}>
                  <i className="fas fa-list"></i> LISTADO DE TÉCNICOS
                </button>
                <button
                  onClick={() => {
                    if (!validaSession()) return;
                    setModalTecnico(true);
                  }}
                >
                  <i className="fas fa-users"></i> AGREGAR NUEVO TÉCNIO
                </button>
              </div>
            )}
            <style>{`
        .dropdown-mc {
          position: relative;
          display: inline-block;
        }

        .dropdown-btn-mc {
          background-color: #4CAF50;
          color: white;
          padding: 10px 20px;
          border: none;
          cursor: pointer;
          border-radius: 5px;
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 8px;
        }

        .dropdown-btn-mc:hover {
          background-color: #45a049;
        }

        .dropdown-content-mc {
          position: absolute;
          background-color: white;
          min-width: 160px;
          max-width: 90vw;
          width: auto;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          z-index: 2;
          overflow: hidden;
          animation: fadeIn 0.3s ease-in-out;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 10px;
          right: 0;
        }

        .dropdown-content-mc button {
          background: none;
          border: none;
          padding: 10px;
          width: 100%;
          text-align: left;
          cursor: pointer;
          font-size: 14px;
          display: flex;
          align-items: center;
          gap: 8px;
          transition: background 0.3s;
        }

        .dropdown-content-mc button:hover {
          background-color: #f1f1f1;
        }

        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(-10px); }
          to { opacity: 1; transform: translateY(0); }
        }

        @media (max-width: 600px) {
          .dropdown-content-mc {
            position: fixed;
            top: 50px;
            left: 50%;
            transform: translateX(-50%);
            width: 90%;
            max-width: 300px;
          }

          .dropdown-btn-mc {
            width: 100%;
          }
        }
          @media (max-width: 599px) {
            .dropdown-content-mc {
                position: absolute;
            background-color: none;
            min-width: 160px;
            max-width: 90vw;
            width: auto;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
            border-radius: 5px;
            z-index: 1;
            overflow: hidden;
            animation: fadeIn 0.3s ease-in-out;
            display: flex
        ;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            right: 0;
            }
        }
      `}</style>
          </div>
        </div>
      </div>
    );
  };

  const getAlarmasfn = () => {
    const alarmas = ["transferencias", "xcaducar", "caducados"];
    return (
      <div
        className="row my-0 text-center w-100 d-flex justify-content-center align-items-center flex-row h-100 p-2"
        style={{ borderBottom: "1px solid black" }}
      >
        <strong>ALARMAS</strong>
        {alarmas.map((data) => (
          <div
            className={" col-md-12 col-sm-12   shadow hoverbtn p-0  m-0 my-1"}
            style={{
              borderRadius: "12px",
              background:
                data === "transferencias"
                  ? "blue"
                  : data === "xcaducar"
                  ? "orange"
                  : "red",
              color: "white",
              fontWeight: "bold",

              transition: "all 0.3s ease",
            }}
            onClick={() => showAlertad(data)}
          >
            <div className="card-body p-0 m-0 p-2 my-1  text-center d-flex justify-content-between align-items-center flex-row">
              <label className="p-0 m-0" style={{}}>
                {data.toUpperCase()}
              </label>
              <label className="p-0 m-0 ">
                ({" "}
                {data === "transferencias"
                  ? solicitudesTranferencias.length
                  : data === "xcaducar"
                  ? xcaducar.length
                  : caducados.length}{" "}
                )
              </label>
            </div>
          </div>
        ))}
      </div>
    );
  };

  useEffect(() => {
    if (dataTabla.length > 0) getTotalesfn();
  }, [estados]);

  const [tratados, settratados] = useState(0);
  const [noejecutado, setnoejecutado] = useState(0);

  const getTotalesfn = async () => {
    const groupedData = estados;
    const finalizadototal = groupedData["FINALIZADO"]
      ? groupedData["FINALIZADO"].length
      : 0;
    const pendientetotal = groupedData["PENDIENTE"]
      ? groupedData["PENDIENTE"].length
      : 0;
    const planificadototal = groupedData["PLANIFICADO"]
      ? groupedData["PLANIFICADO"].length
      : 0;
    const total = finalizadototal + pendientetotal + planificadototal;
    const tratadosporcen = ((finalizadototal + planificadototal) / total) * 100;
    const notratadosporcen = (pendientetotal / total) * 100;
    settratados(tratadosporcen.toFixed(0));
    setnoejecutado(notratadosporcen.toFixed(0));
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="row d-flex justify-content-between ">
            <div className="col-md-4 p-2">
              <div
                style={{ borderRadius: "12px", border: "1px solid black" }}
                className={
                  aplicafiltrogeneral
                    ? "row aplicafiltro p-0 m-0 shadow h-100"
                    : "row nofiltro p-0  m-0  shadow h-100"
                }
              >
                <div className="col-md-12">
                  <div
                    className="row my-0 "
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <strong>FILTRO GENERAL</strong>
                  </div>
                </div>
                <div className="w-100 row ">
                  <div className="col-md-4 p-0 m-0 ">
                    <div className=" row ">
                      <label className="x-0  negrita">Inicio :</label>
                      <div className={"col-md-12 p-0 m-0"}>
                        <input
                          type="date"
                          value={dayjs(startDate).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setStartDate(e.target.value);
                          }}
                          className="inputselect2 bordecompleto bordesombreado"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 p-0 m-0 ">
                    <div className=" row">
                      <label className="x-0  negrita">Fin :</label>
                      <div className={"col-md-12 p-0 m-0"}>
                        <input
                          type="date"
                          value={dayjs(endDate).format("YYYY-MM-DD")}
                          onChange={(e) => {
                            setEndDate(e.target.value);
                          }}
                          className="inputselect2 bordecompleto bordesombreado"
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4  d-flex justify-content-center  align-items-center  flex-row p-2 ">
                    {aplicafiltrogeneral ? (
                      <button
                        onClick={() => {
                          setAplicafiltrogeneral(false);
                        }}
                        style={{ background: "red", color: "white" }}
                        className="dropdown-btn-mc p-2  shadow mx-1"
                      >
                        <span>
                          <i className="fas fa-close"></i>
                        </span>
                      </button>
                    ) : null}
                    <button
                      onClick={() => {
                        setAplicafiltrogeneral(true);
                      }}
                      style={{ background: "#3f3f3f", color: "white" }}
                      className="dropdown-btn-mc p-2  shadow mx-1"
                    >
                      <span>
                        <i className="fas fa-search"></i>
                      </span>
                    </button>
                    {aplicafiltrogeneral ? null : (
                      <button
                        style={{ background: "#80eaff", color: "black" }}
                        className="dropdown-btn-mc  p-2 shadow mx-1"
                        onClick={async () => {
                          getAllMantenimientos();
                        }}
                      >
                        <span>
                          <i className="fas fa-rotate"></i>
                        </span>
                      </button>
                    )}
                  </div>
                </div>
                <hr className="m-0" />
                <div className="row">
                  <div className="col-md-4">
                    <div
                      className="row shadow py-2  d-flex justify-content-center align-items-center flex-column text-center"
                      style={{
                        background: "red",
                        color: "white",
                        borderRadius: "12px",
                      }}
                    >
                      <label htmlFor="">NO EJECUTADOS </label>
                      <label htmlFor="">( {noejecutado}% )</label>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div
                      className="row shadow py-2  d-flex justify-content-center align-items-center flex-column text-center"
                      style={{
                        background: "green",
                        color: "white",
                        borderRadius: "12px",
                      }}
                    >
                      <label htmlFor="">TRATADOS </label>
                      <label htmlFor="">( {tratados}% )</label>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div
                      className="row shadow py-2  d-flex justify-content-center align-items-center flex-column text-center"
                      style={{
                        background: "white",
                        color: "black",
                        borderRadius: "12px",
                        border: "1px solid black",
                      }}
                    >
                      <label htmlFor="">TOTAL </label>
                      <label htmlFor="">( {totalStados.length} )</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                style={{
                  borderRadius: "12px",
                  border: "1px solid black",
                  background: "#f5f5f5",
                }}
                className={
                  aplicafiltrogeneral
                    ? "row aplicafiltro p-0 m-0 shadow w-100 "
                    : "row nofiltro p-0  m-0 shadow w-100"
                }
              >
                <div className="col-md-12">
                  <div
                    className="row  p-0 m-0 text-center w-100 d-flex justify-content-center align-items-center flex-row h-100 px-4 py-2"
                    style={{ borderBottom: "1px solid black" }}
                  >
                    <div className="d-flex justify-content-between align-items-center">
                      {" "}
                      <strong>CONTADORES</strong>
                    </div>
                    {Object.entries(estados).map(([key, values]) => (
                      <div
                        className={
                          selectedbutton === key
                            ? "selectedbtn  col-md-12 col-sm-12  p-0 m-0 shadow hoverbtn  "
                            : " col-md-12 col-sm-12   shadow hoverbtn p-0  m-0 my-1"
                        }
                        style={{
                          borderRadius: "12px",
                          background:
                            key === "PENDIENTE"
                              ? "#dc3545"
                              : key === "FINALIZADO"
                              ? "#198754"
                              : "#ffc107",
                          color:
                            key === "PENDIENTE"
                              ? "white"
                              : key === "FINALIZADO"
                              ? "white"
                              : "black",
                          fontWeight: "bold",
                          border:
                            selectedbutton === key ? "3px solid blue" : "none",
                          boxShadow:
                            selectedbutton === key
                              ? "0px 4px 15px rgba(0,0,0,0.3)"
                              : "none",
                          transform:
                            selectedbutton === key ? "scale(1.05)" : "scale(1)",
                          transition: "all 0.3s ease",
                        }}
                        key={key}
                        onClick={() => {
                          setSelectedButton(key);
                          setDataTabla(values);
                        }}
                      >
                        <div className="card-body p-0 m-0 p-2 my-1  text-center d-flex justify-content-between align-items-center flex-row">
                          <label className="p-0 m-0" style={{}}>
                            {key}
                          </label>
                          <label className="p-0 m-0 ">
                            ( {values.length} )
                          </label>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 p-2">
              <div
                style={{
                  borderRadius: "12px",
                  border: "1px solid black",
                  background: "#f5f5f5",
                }}
                className={
                  aplicafiltrogeneral
                    ? "row aplicafiltro p-0 shadow w-100 "
                    : "row nofiltro p-0   shadow w-100"
                }
              >
                <div className="col-md-12">{getAlarmasfn()}</div>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-md-12 "}>
          <div className="row  py-2  d-flex justify-content-center align-items-center">
            <div
              className="col-md-12 shadow pb-2"
              style={{
                background: "#f1f1f1",
                borderRadius: "12px",
                border: "1px solid black",
              }}
            >
              <div className="row p-0 m-0">
                <div className="col-md-12 p-0 m-0">
                  <div className="row">
                    <div className="col-md-6 col-sm vertical-center d-flex justify-content-start align-items-center ">
                      <label htmlFor="" className="negrita">
                        <i className="fas fa-arrow-right mx-2"></i>DH-LOGS
                      </label>
                    </div>
                    <div className="col-md-6 col-sm d-flex justify-content-end align-items-center">
                      {getMenu()}
                    </div>
                  </div>
                </div>
                <hr className="w-100 p-0 m-0" />
              </div>
              <div className="row">
                <div className=" p-0 m-0 col-md-12 flex flex-col items-center justify-center min-h-screen space-y-4">
                  <style>{`

                  .listtecnicos{
                           max-height:157px;
                           overflow-y:scroll;
                           height:159px;
                  }
                        .custom-box-reporte {
                          background-color: none;
                          color: black;
                          
                          border-radius: 10px;
                        }

                        .custom-show-reporte {
                          opacity: 1;
                          transform: scale(1);
                          max-height: auto;
                          transition: opacity 0.1s ease, transform 0.5s ease, max-height 0.1s ease;

                        }

                        .custom-hide-reporte {
                          opacity: 0;
                          transform: scale(0.8);
                          max-height: 0;

                        }
                   `}</style>

                  <div
                    className={` custom-box-reporte col-md-12  ${
                      filtroAvanzadoporreporte
                        ? "custom-show-reporte "
                        : "custom-hide-reporte"
                    }`}
                  >
                    <div className="row py-5">
                      <div className="col-md-12">
                        <label
                          htmlFor=""
                          className="text-dark "
                          style={{ fontWeight: "bold" }}
                        >
                          <i className="fas fa-filter mx-2"></i>FICHAS
                        </label>
                      </div>
                      <div className="col-md-12">
                        <div className="row px-0 py-2 m-0">
                          <div className="col-md-6 col-sm d-flex flex-column py-0 px-3 m-0 justify-content-start ">
                            <label htmlFor="">Filtrar por ficha/nombres</label>
                            <input
                              style={{ height: "40px", borderRadius: "5px" }}
                              className="w-100 shadow"
                              type="text"
                              placeholder="Escriba aquí ..."
                              onChange={filtrarPorFichaID}
                            />
                          </div>
                          <div className="col-md-6 col-sm d-flex flex-column py-0 px-3 m-0 justify-content-start ">
                            <label htmlFor="">
                              Filtrar por criterio de la condición insegura
                            </label>
                            <input
                              style={{ height: "40px", borderRadius: "5px" }}
                              className="w-100 shadow"
                              type="text"
                              placeholder="Escriba aquí ..."
                              onChange={filtrarPorCriterio}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row ">
                <div className=" p-0 m-0 col-md-12 flex flex-col items-center justify-center min-h-screen space-y-4 bg-white">
                  <style>{`

                  .listtecnicos{
                           max-height:157px;
                           overflow-y:scroll;
                           height:159px;
                  }
                        .custom-box {
                          background-color: none;
                          color: black;
                          
                          border-radius: 10px;
                          transition: opacity 0.5s ease, transform 0.5s ease, max-height 0.5s ease;
                        }

                        .custom-show {
                          opacity: 1;
                          transform: scale(1);
                          max-height: auto;
                        }

                        .custom-hide {
                          opacity: 0;
                          transform: scale(0.8);
                          max-height: 0;
                        }
                   `}</style>

                  <div
                    className={` custom-box col-md-12  ${
                      filtroAvanzado ? "custom-show " : "custom-hide"
                    }`}
                  >
                    <div className="row">
                      {/* <div className="col-md-12">
                        <label
                          htmlFor=""
                          className="text-dark "
                          style={{ fontWeight: "bold" }}
                        >
                          <i className="fas fa-filter mx-2"></i> FILTRO AVANZADO
                        </label>
                        <hr className="mx-0 my-1 p-0" />
                      </div> */}
                      <div className="col-md-12 p-0 m-0 mt-3">
                        <div className="row m-0 p-0">
                          {istecnico ? (
                            <div className="col-md-4 my-2 ">
                              <div className="w-100">
                                <p
                                  className="my-1"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Identifiación :
                                </p>
                                <label>{session.usuario}</label><br></br>
                                <label> {session.nombres} {session.apellidos}</label>
                               
                                <p
                                  className="my-1"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Area :
                                </p>
                                <p>{session.area.pre_area}</p>
                                <p
                                  className="my-1"
                                  style={{ fontWeight: "bold" }}
                                >
                                  Identifiacado como ténico :
                                </p>
                                <p>SI</p>
                              </div>
                            </div>
                          ) : (
                            <div className="col-md-4 my-2 ">
                              <div className="w-100">
                                <div className="d-flex justify-content-between align-items-center">
                                  <label
                                    htmlFor=""
                                    style={{ fontWeight: "bold" }}
                                  >
                                    <i className="fas fa-list mx-2"></i> LISTADO
                                    DE TECNICOS
                                  </label>
                                  <label htmlFor="">
                                    checked (
                                    {
                                      listadoTecnicos.filter(
                                        (data) => data.checked
                                      ).length
                                    }
                                    ) de ({listadoTecnicos.length})
                                  </label>
                                  {/*  <button onClick={() => getAllTecnicos()} style={{borderRadius:"12px"}}>
                                  <i className="fas fa-rotate"></i>
                                </button> */}
                                </div>
                                <hr className="w-100 p-0 m-0" />
                              </div>
                              <div className="listtecnicos">
                                <table className="w-100">
                                  <tbody className="">
                                    {listadoTecnicos.map((data, index) => (
                                      <tr className="w-100 border d-flex justify-content-start align-items-center py-2">
                                        <td className="px-2">
                                          <input
                                            onChange={async () => {
                                              const res = await listadoTecnicos.map(
                                                (data2) => {
                                                  if (
                                                    data.mantecnico_id ===
                                                    data2.mantecnico_id
                                                  ) {
                                                    data2.checked = !data2.checked;
                                                  }
                                                  return data2;
                                                }
                                              );
                                              setListadoTecnicos(res);
                                              aplicarfiltrotecnicosFn(
                                                "tecnicoscheck"
                                              );
                                            }}
                                            type="checkbox"
                                            checked={
                                              data.checked ? true : false
                                            }
                                          />
                                        </td>
                                        <td className=" d-flex w-100 justify-content-between align-items-center">
                                          <strong>
                                            {" "}
                                            ({index + 1}){" "}
                                            {data.infopersona.nombres}{" "}
                                            {data.infopersona.apellidos}
                                          </strong>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              {/*  {listadoTecnicos.map((data)=>{
                          if(data.checked===true){
                            return <div className="text-dark">{data.infopersona.nombres}</div>
                          }
                        })} */}

                              {/* <ReactSelect
                          name="tecnicos"
                          isClearable={true} // Permite limpiar con un botón de "x"
                          options={listTecnicosFilter}
                          className="basic-multi-select h-100"
                          classNamePrefix="select"
                          placeholder="Listado de técnicos"
                          closeMenuOnSelect={false}
                          value={""}
                          components={animatedComponents}
                          onChange={async (e) => {
                            let aux = [];
                            const res = await listadoTecnicos.filter(
                              (data2) =>
                                parseInt(e.value) ===
                                parseInt(data2.mantecnico_id)
                            );
                            if (res.length > 0) {
                              const resx = await listadoTecnicosfilter.filter(
                                (x) =>
                                  parseInt(res[0].mantecnico_id) ===
                                  parseInt(x.mantecnico_id)
                              );
                              if (resx.length === 0) {
                                aux.push(res[0]);
                              }
                            }
                            setListadoTecnicosfilter(
                              listadoTecnicosfilter.concat(aux)
                            );
                          }}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            control: (base) => ({
                              ...base,
                              color: "white", // Color del texto dentro del input
                              backgroundColor: "black",
                              fontSize: "15px",
                              height: "100%",
                              // Fondo del select
                            }),
                            singleValue: (base) => ({
                              ...base,
                              color: "white", // Color del texto seleccionado
                            }),
                            placeholder: (base) => ({
                              ...base,
                              color: "gray", // Color del texto del placeholder
                            }),
                            menu: (base) => ({
                              ...base,
                              backgroundColor: "black", // Fondo del menú desplegable
                            }),
                            option: (base, { isFocused, isSelected }) => ({
                              ...base,
                              backgroundColor: isSelected
                                ? "gray"
                                : isFocused
                                ? "darkgray"
                                : "black",
                              color: "white", // Color del texto de las opciones
                            }),
                          }}
                        /> */}
                            </div>
                          )}

                          <div className="col-md-2 my-2">
                            <div className="w-100">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  htmlFor=""
                                  style={{ fontWeight: "bold" }}
                                >
                                  <i className="mx-2 fas fa-warning"></i>
                                  PRIORIDAD
                                </label>
                                <label htmlFor="">
                                  (
                                  {
                                    prioridadfilteravance.filter(
                                      (data) => data.checked
                                    ).length
                                  }
                                  ) de ({prioridadfilteravance.length})
                                </label>
                              </div>
                              <hr className="w-100 p-0 m-0" />
                            </div>
                            <div className="row">
                              <table className="w-100">
                                <tbody className="">
                                  {prioridadfilteravance.map((data, index) => (
                                    <tr
                                      className="w-100 border d-flex justify-content-start align-items-center py-2"
                                      key={index}
                                    >
                                      <td className="px-2">
                                        <input
                                          onChange={async () => {
                                            const res = await prioridadfilteravance.map(
                                              (data2) => {
                                                if (
                                                  data.prioridad ===
                                                  data2.prioridad
                                                ) {
                                                  data2.checked = !data2.checked;
                                                }
                                                return data2;
                                              }
                                            );
                                            setPrioridadfilteravance(res);
                                            aplicarfiltrotecnicosFn();
                                          }}
                                          type="checkbox"
                                          checked={data.checked ? true : false}
                                        />
                                      </td>
                                      <td
                                        style={{
                                          background: data.bg,
                                          color: data.color,
                                          borderRadius: "12px",
                                        }}
                                        className=" py-1 px-2 d-flex w-100 justify-content-between align-items-center"
                                      >
                                        <strong>
                                          {data.prioridad
                                            ? data.prioridad
                                            : "NO DEFINIDOS"}
                                        </strong>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                          <div className="col-md-2 my-2">
                            <div className="w-100">
                              <div className="d-flex justify-content-between align-items-center">
                                <label
                                  htmlFor=""
                                  style={{ fontWeight: "bold" }}
                                >
                                  <i className="mx-2 fas fa-warning"></i>ESTADO
                                </label>
                                <label htmlFor="">
                                  (
                                  {
                                    estadofilteravance.filter(
                                      (data) => data.checked
                                    ).length
                                  }
                                  ) de ({estadofilteravance.length})
                                </label>
                              </div>
                              <hr className="w-100 p-0 m-0" />
                            </div>
                            <table className="w-100">
                              <tbody className="">
                                {estadofilteravance.map((data, index) => (
                                  <tr
                                    className="w-100 border d-flex justify-content-start align-items-center py-2"
                                    key={index}
                                  >
                                    <td className="px-2">
                                      <input
                                        onChange={async () => {
                                          const res = await estadofilteravance.map(
                                            (data2) => {
                                              if (
                                                data.estado === data2.estado
                                              ) {
                                                data2.checked = !data2.checked;
                                              }
                                              return data2;
                                            }
                                          );
                                          setestadofilteravance(res);
                                          aplicarfiltrotecnicosFn();
                                        }}
                                        type="checkbox"
                                        checked={data.checked ? true : false}
                                      />
                                    </td>
                                    <td
                                      style={{
                                        background: data.bg,
                                        color: data.color,
                                        borderRadius: "12px",
                                      }}
                                      className=" d-flex w-100 py-1  px-2 justify-content-between align-items-center"
                                    >
                                      <strong> {data.estado} </strong>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                          <div className=" col-md-4 my-2 ">
                            <div className="w-100">
                              <div className=" d-flex justify-content-between align-items-center justify-content-center ">
                                <label
                                  htmlFor=""
                                  style={{ fontWeight: "bold" }}
                                >
                                  <i className="mx-2 fas fa-calendar"></i>
                                  PLANIFICACION
                                </label>
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="px-2">
                                    <input
                                      type="checkbox"
                                      className=""
                                      checked={filtrorangos}
                                      onChange={(e) => {
                                        setfiltrorangos(e.target.checked);
                                        setfiltronulos(false);
                                      }}
                                    />
                                    <label htmlFor="">Rango</label>
                                  </div>
                                  <div>
                                    <input
                                      type="checkbox"
                                      className=""
                                      checked={filtronulos}
                                      onChange={async (e) => {
                                        console.log(e.target.checked, "test");
                                        setfiltronulos(e.target.checked);
                                        setfiltrorangos(false);
                                        setStartDate("");
                                        setEndDate("");
                                      }}
                                    />
                                    <label htmlFor="">nulos</label>
                                  </div>
                                </div>
                              </div>
                              <hr className="w-100 p-0 m-0" />
                            </div>
                            {filtrorangos ? (
                              <div className="row ">
                                <div className="col-md-12 py-2">
                                  <div className=" row boder">
                                    <label className="x-0  negrita text-dark">
                                      Fecha de planificación inicial:
                                    </label>
                                    <div className={"col-md-12 p-0 m-0"}>
                                      <input
                                        type="date"
                                        value={dayjs(
                                          startDateplanificada
                                        ).format("YYYY-MM-DD")}
                                        onChange={(e) => {
                                          setStartDateplanificada(
                                            e.target.value
                                          );
                                        }}
                                        className="inputselect2 bordecompleto bordesombreado"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="col-md-12 
                               "
                                >
                                  <div className=" row">
                                    <label className="x-0  negrita text-dark">
                                      Fecha de planificación final :
                                    </label>
                                    <div className={"col-md-12 p-0 m-0"}>
                                      <input
                                        type="date"
                                        value={dayjs(endDateplanificada).format(
                                          "YYYY-MM-DD"
                                        )}
                                        onChange={(e) => {
                                          setEndDateplanificada(e.target.value);
                                        }}
                                        className="inputselect2 bordecompleto bordesombreado"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="col-md-6 mt-2">
                  <label htmlFor="">Ordenar</label>
                  <select
                    className="form-select"
                    value={sortOrder}
                    onChange={(e) => setSortOrder(e.target.value)}
                  >
                    <option value="asc">ASCENDENTE</option>
                    <option value="desc">DESCENDENTE</option>
                  </select>
                </div>
 */}
                                <div className="col-md-12 px-3 py-2 m-0  d-flex justify-content-end align-items-end  ">
                                  <button
                                    className="p-2 border shadow w-100  "
                                    style={{
                                      background: "black",
                                      color: "white",
                                      borderRadius: "12px",
                                    }}
                                    onClick={() => aplicarfiltrotecnicosFn()}
                                  >
                                    <i className="mx-2 fas fa-search"></i>{" "}
                                    <label htmlFor="">APLICAR RANGO</label>
                                  </button>
                                </div>
                              </div>
                            ) : (
                              <div className="row p-3">
                                <p>
                                  * Marca la opción de rangos si deseas aplicar
                                  filtros por fechas de planificación
                                </p>
                                <p>
                                  * Marca la opción de nulos si deseas aplicar
                                  filtros que no están planificados
                                </p>
                                <label htmlFor="">
                                  * Si no se lecciona ninguna opción el filtro
                                  no aplicará
                                </label>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <MantenimientoTable
        allDefectos={allDefectos}
        rol={session?.roles.code}
        isAdmin={true}
        istecnico={istecnico}
        novedades={novedades}
        setNovedades={setNovedades}
        data={dataTabla}
        actualizarTabla={() => getAllMantenimientos()}
      />

      {/*MODAL CREAR EDITAR TECNICOS*/}

      <ModalDefault
        bandera={"tecnico"}
        modal={modalTecnico} //showModal
        size={"lg"} //size
        icontitulo={<null />} //icontitulo
        titulo={`Nuevo técnico`} //titulo
        closeAction={() => {
          setModalTecnico(false);
        }} //onClosedModal
      >
        <CrearEditarTecnicos
          setModalTecnico={(data) => setModalTecnico(data)}
        ></CrearEditarTecnicos>
      </ModalDefault>

      {/*MODAL listar TECNICOS*/}
      <ModalDefault
        bandera={"tecnico"}
        modal={modalListarTecnico} //showModal
        size={"lg"} //size
        icontitulo={<null />} //icontitulo
        titulo={`Listado de técnicos`} //titulo
        closeAction={() => {
          setModalListarTecnico(false);
        }} //onClosedModal
      >
        {listadoTecnicos.map((data, index) => (
          <tr className="w-100 border d-flex justify-content-start align-items-center">
            <td className=" d-flex w-100 justify-content-between align-items-center">
              <strong>
                {" "}
                ({index + 1}) {data.infopersona.nombres}{" "}
                {data.infopersona.apellidos}
              </strong>
            </td>
          </tr>
        ))}
      </ModalDefault>

      {/*MODAL listar TECNICOS filtros*/}
      <ModalDefault
        bandera={"tecnico"}
        modal={modalListarTecnicoFiltros} //showModal
        size={"lg"} //size
        icontitulo={<null />} //icontitulo
        titulo={`Listado de técnicos`} //titulo
        closeAction={() => {
          setModalListarTecnicoFiltros(false);
        }} //onClosedModal
      >
        <div className="w-100 d-flex flex-column ">
          <table className="w-100">
            <tbody>
              {listadoTecnicos.map((data, index) => (
                <tr className="w-100 border d-flex justify-content-start align-items-center py-2">
                  <td className="px-2">
                    <input
                      onChange={async () => {
                        const res = await listadoTecnicos.map((data2) => {
                          if (data.mantecnico_id === data2.mantecnico_id) {
                            data2.checked = !data2.checked;
                          }
                          return data2;
                        });
                        setListadoTecnicos(res);
                      }}
                      type="checkbox"
                      checked={data.checked ? true : false}
                    />
                  </td>
                  <td className=" d-flex w-100 justify-content-between align-items-center">
                    <strong>
                      {" "}
                      ({index + 1}) {data.infopersona.nombres}{" "}
                      {data.infopersona.apellidos}
                    </strong>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div className="w-100 px-5 p-2">
            <button
              onClick={async () => {
                aplicarfiltrotecnicosFn();
              }}
              className="w-100 p-2"
              style={{
                background: "black",
                color: "white",
                borderRadius: "12px",
              }}
            >
              <i className="fas fa-search" style={{ fontSize: "16px" }}></i>{" "}
              <label htmlFor="">Aplicar</label>
            </button>
          </div>
        </div>
      </ModalDefault>

      {/**Modal tipo de defectos */}
      <ModalDefault
        bandera={"tecnico"}
        modal={modaldefectos} //showModal
        size={"lg"} //size
        icontitulo={<null />} //icontitulo
        titulo={`Configuración de defectos`} //titulo
        closeAction={() => {
          setModalDefectos(false);
        }} //onClosedModal
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row m-0 p-5" style={{ background: "#f1eaea" }}>
              <table className="w-100">
                <thead className="w-100">
                  <tr>
                    <th>NOMBRE DEL TIPO</th>
                    <th className="text-center">SELECCIONADO</th>
                    <th>OPCIONES</th>
                  </tr>
                </thead>
                <tbody className="w-100">
                  <tr className="w-100">
                    <td>
                      <input
                        name="nombre"
                        type="text"
                        className="inputtext2 rounded w-100"
                        value={statedefecto.nombre}
                        onChange={onchangedefecto}
                      />
                    </td>
                    <td className="">
                      <div className="w-100 h-100 d-flex justify-content-center align-items-center text-center">
                        <input
                          name="estado"
                          type="checkbox"
                          checked={statedefecto.estado}
                          onChange={onchangedefecto}
                        />
                      </div>
                    </td>
                    <td>
                      {statedefecto.tipo_defecto_id ? (
                        <button
                          className="shadow border rounded"
                          onClick={() => editarDefectoFn()}
                        >
                          <h5>Guardar cambios</h5>
                        </button>
                      ) : (
                        <button
                          className="shadow border rounded"
                          onClick={() => guardarDefectoFn()}
                        >
                          <h5>Crear nuevo defecto</h5>
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <br />
            <br />
            Listado de tipo de defectos
            <hr />
            <div className="row">
              <table className="w-100">
                <thead className="w-100">
                  <tr>
                    <th>NOMBRE DEL TIPO</th>
                    <th className="text-center">SELECCIONADO</th>
                    <th>OPCIONES</th>
                  </tr>
                </thead>
                <tbody className="w-100 py-2">
                  {allDefectos.length > 0 ? (
                    allDefectos.map((data, i) => (
                      <tr
                        key={i}
                        style={{ borderTop: "1px solid #a59696" }}
                        className=""
                      >
                        <td className="p-2">{data.nombre}</td>
                        <td className="text-center d-flex justify-content-center align-items-center">
                          <br />
                          <br />
                          <input
                            name="estado"
                            type="checkbox"
                            readOnly
                            checked={data.estado}
                          />
                        </td>
                        <td>
                          <div className="w-100">
                            <button
                              className="mx-1"
                              style={{ background: "red", color: "white" }}
                              onClick={() => {
                                eliminarDefectoFn(data.tipo_defecto_id);
                              }}
                            >
                              Eliminar
                            </button>
                            <button
                              className="mx-1"
                              style={{ background: "green", color: "white" }}
                              onClick={() => {
                                setStateDefecto({
                                  ...statedefecto,
                                  tipo_defecto_id: data.tipo_defecto_id,
                                  nombre: data.nombre,
                                  estado: data.estado,
                                });
                              }}
                            >
                              Editar
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="w-100 d-flex justify-content-center align-items-center">
                      <td className="text-center" colSpan={4}>
                        No hay tipos de defectos registrados
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ModalDefault>

      {/**Modal descargar excel */}
      <ModalDefault
        bandera={"excel"}
        modal={modalexcel} //showModal
        size={"lg"} //size
        icontitulo={<null />} //icontitulo
        titulo={`Generar excel`} //titulo
        closeAction={() => {
          setModalExcel(false);
        }} //onClosedModal
      >
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              {camposDisponibles.length > 0 ? (
                <div className="w-100">
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <h6>
                      Seleccione el orden que se generará los registros del
                      excel
                    </h6>
                    <button
                      onClick={() => setCamposSeleccionado(camposDisponibles)}
                      className="shadow border p-3 "
                      style={{
                        background: "black",
                        color: "white",
                        borderRadius: "12px",
                      }}
                    >
                      Reiniciar listado
                    </button>
                  </div>

                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="items">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            listStyleType: "none",
                            padding: 0,
                            maxWidth: "300px",
                            margin: "0 auto",
                          }}
                        >
                          {camposSeleccionados.map((item, index) => (
                            <Draggable
                              key={item}
                              draggableId={item}
                              index={index}
                            >
                              {(provided) => (
                                <li
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={{
                                    padding: "10px",
                                    margin: "5px 0",
                                    background: "#f4f4f4",
                                    border: "1px solid #ddd",
                                    borderRadius: "5px",
                                    textAlign: "center",
                                    ...provided.draggableProps.style,
                                  }}
                                >
                                  <div className="w-100 d-flex justify-content-between aling-items-center">
                                    <div className="px-2">
                                      <label htmlFor="">( {index + 1} )</label>
                                      <strong className="mx-2">{item}</strong>
                                    </div>
                                    <button
                                      onClick={() => {
                                        const res = camposSeleccionados.filter(
                                          (data, index1) => index1 !== index
                                        );
                                        setCamposSeleccionado(res);
                                      }}
                                      className="shadow px-3"
                                      style={{
                                        background: "red",
                                        color: "white",
                                        borderRadius: "25px",
                                        border: "none",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <label
                                        htmlFor=""
                                        style={{ fontSize: "16px" }}
                                      >
                                        -
                                      </label>
                                    </button>
                                  </div>
                                </li>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </ul>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              ) : null}
            </div>
            <div className="row">
              <button
                onClick={() => descargarExcel(camposSeleccionados)}
                className="shadow border my-2"
                style={{
                  background: "black",
                  color: "white",
                  padding: "12px",
                  borderRadius: "7px",
                  border: "none",
                }}
              >
                {" "}
                <i className="fas fa-download"></i> Descargar
              </button>
            </div>
          </div>
        </div>
      </ModalDefault>
    </div>
  );
};

export default Mantenimientos;
