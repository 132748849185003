import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";
const ModalDefault = (props) => {
  //normalize('NFD').replace(/[\u0300-\u036f]/g,"")

  const modal = props.modal;
  const [state, setState] = useState(false);

  useEffect(() => {
    setState(modal);
  }, [modal]);

  const cerrarModal = () => {
    props.closeAction(!modal, props.bandera);
  };

  return (
    <Modal
      isOpen={state}
      toggle={() => {}}
      centered
      size={props.size ? props.size : ""}
      style={{
        maxWidth: props.maxWidth,
        marginRight: props.mx,
        marginLeft: props.mx,
        marginTop: props.my,
        marginBottom: props.my,
      }}
      fullscreen={props.fullscreen}
    >
      <ModalHeader
        toggle={() => {
          setState(!modal);
          cerrarModal();
        }}
        style={{ background: "white" }}
        className={props.classnameheader}
      >
        <label style={{ margin: "0", fontSize: "14px" }}>
          {props.icontitulo}
          {props.titulo}
        </label>
        {props.needfullscreen ? (
          <button
          onClick={()=>{
            props.onClickFullscreen()
          }}
            style={{
              border: "1px solid black",
              borderRadius: "25px",
              background: "white",
              padding: "2px 10px"
            }}
          >
            <i className="fas fa-expand"></i>
          </button>
        ) : null}
      </ModalHeader>
      <ModalBody className={props.classnamebody}>{props.children}</ModalBody>
    </Modal>
  );
};

export default ModalDefault;
