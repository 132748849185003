import React, { Component } from "react";
import logo from "../assets/images/lg.png";
import "./SplashMessage.css";
import { ApiYo } from "../api/auth/ApiAuth";

function SplashMessage() {
  return (
    <div className="splash-container">
      <div className="loading-circle" />
      <div className="logo-container">
        <img src={logo} alt="Logo" className="splash-logo" />
      </div>
    </div>
  );
}
export default function withSplashScreen(WrappedComponent) {
  return class extends Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
      };
    }

    async componentDidMount() {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const token = localStorage.getItem("accessTokenAdm");

        if (!token) {
          return this.setState({ loading: false });
        }
        const response = await ApiYo({ token: token });
        if (response.sms === "ok") {
          localStorage.setItem("datauser", JSON.stringify(response.data));
          return this.setState({ loading: false });
        } else {
          localStorage.removeItem("accessTokenAdm");
          localStorage.removeItem("datauser");
          window.location="/"
          return this.setState({ loading: false });
        }
      } catch (err) {
        console.log(err);
        this.setState({
          loading: false,
        });
      }
    }

    render() {
      // mientras revisa la sesión del usuario, muestra el mensaje "cargando"
      if (this.state.loading) return SplashMessage();

      // en caso contrario, muestra la ruta deseada
      return <WrappedComponent {...this.props} />;
    }
  };
}
