import Axios from "../config/Axios";
export const Apicreartecnico = async (data) => {
  try {
    const result = await Axios.post(`/POSTCreateMantenimientoTecnicos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApicreartecnicoAsignacion = async (data) => {
    try {
      const result = await Axios.post(`/POSTCreateAsignacionMantenimientoTecnicos`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

export const ApiEditarTecnico = async (data) => {
  try {
    const result = await Axios.post(`/POSTEditarMantenimientoTecnicos`, data);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllTecnicos = async (data) => {
  try {
    const result = await Axios.get(`/POSTGetMantenimientoTecnicos`);
    return result.data;
  } catch (e) {
    return [
      {
        sms: "err",
        data: [],
        mensaje: e,
      },
    ];
  }
};

export const ApiGetAllTecnicosAsignados = async (data) => {
    try {
      const result = await Axios.get(`/POSTGetMantenimientoTecnicosAsignados/${data}`);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

  export const ApiQuitarTecnicosAsignados = async (data) => {
    try {
      const result = await Axios.get(`/POSTQuitarMantenimientoTecnicosAsignados/${data}`);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };


  export const ApiactualizarCaso = async (data) => {
    try {
      const result = await Axios.post(`/POSTeditMantenimientoCase`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

  export const ApiactualizarCasofromecxcel = async (data) => {
    try {
      const result = await Axios.post(`/POSTeditMantenimientoCaseexcel`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };
  export const ApiFechaPlanificada = async (data) => {
    try {
      const result = await Axios.post(`/POSTGetMantenimientoPlanificada`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

  export const ApiCerrarCaso = async (data) => {
    try {
      const result = await Axios.post(`/POSTGetMantenimientoCierre`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };


  //DEFECTOS
  //================================================================================
  export const ApicrearDefecto = async (data) => {
    try {
      const result = await Axios.post(`/POSTcreateMantenimientosTiposDefectos`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };



  export const ApiEditarTiposDefectos= async (data) => {
    try {
      const result = await Axios.post(`/POSTeditMantenimientosTiposDefectos`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

  export const ApiEliminarTiposDefectos= async (data) => {
    try {
      const result = await Axios.post(`/POSTEliminarMantenimientosTiposDefectos`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

  export const ApiObtenerTiposDefectos= async (data) => {
    try {
      const result = await Axios.get(`/GETallMantenimientosTiposDefectos`);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

  export const ApisolicitudTransferenciaFichaDelCaso= async (data) => {
    try {
      const result = await Axios.post(`/POSTSolicitudTransferenciaFicha`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

  export const ApiCancelarsolicitudTransferenciaFichaDelCaso= async (data) => {
    try {
      const result = await Axios.post(`/POSTCancelarSolicitudTransferenciaFicha`, data);
      return result.data;
    } catch (e) {
      return [
        {
          sms: "err",
          data: [],
          mensaje: e,
        },
      ];
    }
  };

