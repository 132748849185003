import React, { useState, useEffect } from "react";
import { DataTable } from "mantine-datatable";

const Tablacasosdemantenimiento = ({ data }) => {
  const [page, setPage] = useState(1);
  const [fetching, setfetching] = useState(true);
  const [paginatedRoles, setPaginatedRoles] = useState([]);
  const columns = [
    {
      accessor: "evento",
      title: "Evento",
      textAlignment: "center",
    },
    {
      accessor: "prioridad",
      title: "Prioridad",
      textAlignment: "center",
    },
    {
      accessor: "tecnico",
      title: "Tecnico",
      textAlignment: "center",
    },
    {
      accessor: "ot",
      title: "ot",
      textAlignment: "center",
    },
    {
      accessor: "costo",
      title: "costo",
      textAlignment: "center",
    },
    {
      accessor: "planificacion",
      title: "planificacion",
      textAlignment: "center",
    },
   
  ];

  const PAGE_SIZE = 15;
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setfetching(true);
    init();
  }, [data]);

  const init = async () => {
    setRoles(data);
    const paginatedData = paginateData(data);
    setPaginatedRoles(paginatedData);
    setfetching(false);
  };

  const paginateData = (data) => {
    const startIndex = (page - 1) * PAGE_SIZE;
    const endIndex = startIndex + PAGE_SIZE;
    return data.slice(startIndex, endIndex);
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
    const paginatedData = paginateData(roles);
    setPaginatedRoles(paginatedData);
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <DataTable
          borderRadius="md"
          className="bordecompleto bgfondo "
          highlightOnHover
          noRecordsText="No se encontraron datos"
          records={paginatedRoles}
          columns={columns}
          totalRecords={data.length}
          recordsPerPage={PAGE_SIZE}
          page={page}
          onPageChange={handlePageChange}
          withColumnBorders
          fetching={fetching}
        />
      </div>
    </div>
  );
};

export default Tablacasosdemantenimiento;
