import { useEffect, useState } from "react";
import { Apicreartecnico, ApiEditarTecnico } from "../../../api/tecnicos";
const CrearEditarTecnicos = ({ data, editar = false,setModalTecnico }) => {
  let infopersonaobject = {
    identificacion: "",
    nombres: "",
    apellidos: "",
    correo: "",
    telefono: "",
  };
  const [state, setState] = useState({
    identificacion: "",
    infopersona: infopersonaobject,
  });
  useEffect(() => {}, [data]);
  const onchangeData = (e) => {
    let infopersonaaux = state.infopersona;
    infopersonaaux[e.target.name] = e.target.value;

    setState({
      ...state,
      infopersona: infopersonaaux,
    });
  };

  const crearEditar = async () => {
    const res = editar ? null : await Apicreartecnico(state);
    if (res.sms === "ok") {
      alert(res.mensaje);
      setState({
        ...state,
        infopersona: infopersonaobject,
      });
      setModalTecnico(false);
    } else {
      alert(res.mensaje);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-10 d-flex flex-column">
            <h5>Identificación:</h5>
            <input
              name="identificacion"
              type="text"
              className="inputtext2 rounded"
              value={
                state.infopersona ? state.infopersona.identificacion : null
              }
              onChange={onchangeData}
            />
          </div>
          <div className="col-md-2 d-flex flex-row ">
            <button className="border shadow rounded w-100">
              <i className="fas fa-search"></i> Filtrar
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex flex-column py-3">
            <h5>Nombres:</h5>
            <input
              name="nombres"
              type="text"
              className="inputtext2 rounded "
              onChange={onchangeData}
              value={state.infopersona ? state.infopersona.nombres : null}
            />
          </div>
          <div className="col-md-12 d-flex flex-column py-3">
            <h5>Apellidos:</h5>
            <input
              name="apellidos"
              type="text"
              className="inputtext2 rounded "
              onChange={onchangeData}
              value={state.infopersona ? state.infopersona.apellidos : null}
            />
          </div>
          <div className="col-md-12 d-flex flex-column py-3">
            <h5>Correo:</h5>
            <input
              name="correo"
              type="text"
              className="inputtext2 rounded "
              onChange={onchangeData}
              value={state.infopersona ? state.infopersona.correo : null}
            />
          </div>
          <div className="col-md-12 d-flex flex-column py-3">
            <h5>Teléfono:</h5>
            <input
              name="telefono"
              type="text"
              className="inputtext2 rounded "
              onChange={onchangeData}
              value={state.infopersona ? state.infopersona.telefono : null}
            />
          </div>
        </div>
        <div className="row">
          <button
            onClick={() => crearEditar()}
            className="shadow border rounded p-2 m-2 "
            style={{ color: "white", background: "black" }}
          >
            Crear registro
          </button>
        </div>
      </div>
    </div>
  );
};

export default CrearEditarTecnicos;
