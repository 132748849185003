import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

import { ApiLogin } from "../../api/auth/ApiAuth";
import { ReactComponent as LockIcon } from "../../icons/lock.svg";
import { ReactComponent as ArrobaIcon } from "../../icons/useredit.svg";
import logo from "../../assets/images/lg.png";
import logo2 from "../../assets/images/bg.png";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    usuario: "",
    contrasena: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    document.getElementById("usuario").focus();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const resp = await ApiLogin(formData);
    const { sms, mensaje, data } = resp;
    if (sms === "ok") {
      localStorage.setItem("accessTokenAdm", data);
      window.location.href = "/";
    } else {
      toast.error(mensaje);
      setLoading(false);

    }
  };

  return (
    <div>
      <div
        className="w-100 d-flex justify-content-between"
        style={{
          height: "100vh",
        }}
      >
        <div className=" d-flex justify-content-center align-items-center flex-column bgfondo w-70 ">
          <img
            src={logo2}
            className="w-100 h-100"
            alt=""
            style={{ opacity: "0.5" }}
          />
        </div>
        <div className="w-30 d-flex justify-content-center flex-column fondocolor  ">
          <div className=" w-100">
            <form className="form card-body " id="">
              <div className="w-100 text-centerv d-flex justify-content-center align-items-center flex-column">
                <div
                  style={{
                    height: "300px",
                    width: "300px",
                  }}
                  className=" mx-5  d-flex justify-content-center align-items-center"
                >
                  <img src={logo} className="w-100" alt="" />
                </div>
              </div>
              <div className="form-group mb-2 bordecompleto bordesombreado">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-center align-items-center inputIcon bordecompleto bordesombreado">
                    <ArrobaIcon />
                  </div>
                  <input
                    className="inputtext  w-80"
                    type="text"
                    placeholder="Digite su cedula"
                    name="usuario"
                    id="usuario"
                    value={formData.usuario}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group bordecompleto bordesombreado">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex justify-content-center align-items-center inputIcon bordecompleto bordesombreado">
                    <LockIcon />
                  </div>
                  <input
                    className="inputtext "
                    type="password"
                    name="contrasena"
                    placeholder="Digite su contraseña"
                    value={formData.contrasena}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="">
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={loading}
                  className="bg-btn-vet azulmarino bordecompleto w-100 p-3 my-3   bordesombreado"
                >
                  {loading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only letrablanca">Loading...</span>
                    </div>
                  ) : (
                    <label className="text-btn negrita letrablanca ">
                      INICIAR SGFORMS
                    </label>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
